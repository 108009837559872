import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Assets, Spacer, TextCapitalized, TextClickable } from "@qivia/ui";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { colors } from "@qivia/ui/src/styles/figmaColors";

type PageType =
  | "activateCard"
  | "vehicleNotInCirculation"
  | "qrCode"
  | "driverBlocked"
  | "";

export const ErrorPage = (props: { page?: PageType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledContainer>
      <Spacer x={2} />
      <StyledColumn>
        <Spacer y={2} />
        <StyledContent>
          <Assets.ErrorPageLogo />
          <StyledTitle>
            <TextCapitalized>
              {props.page === "driverBlocked"
                ? t(`error.${props.page}.title`)
                : t("error.title")}
            </TextCapitalized>
          </StyledTitle>
          <Spacer y={1.5} />
          <StyledBody>
            <TextCapitalized>
              {props.page ? t(`error.${props.page}.body`) : ""}
            </TextCapitalized>
          </StyledBody>
          <Spacer y={1.5} />
        </StyledContent>
        <Spacer y={1.5} />
        <TextClickable capitalized onClick={() => navigate("/help")}>
          {t("error.needHelp")}
        </TextClickable>
        <Spacer y={2} />
      </StyledColumn>
      <Spacer x={2} />
    </StyledContainer>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: ${colors["colors/text/black"]};
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
`;

const StyledTitle = styled.div`
  ${typographies["Title/H1"]};
`;

const StyledBody = styled.div`
  ${typographies["Paragraph/P1"]};
`;
