import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import {
  QueryStatus,
  axiosInstance,
  axiosInstanceWithPhoneToken,
} from "../../utils";
import { GetPresignedUrlType, TermsAndPolicyType } from "./driverLoginApi";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

export interface driverLoginState {
  driverTermsAndPolicyApprovedStatus: QueryStatus;
  driverPhone: string | null;
  isPhoneTokenDecoded: boolean;
  rawToken: string | null;
}

const initialState: driverLoginState = {
  driverTermsAndPolicyApprovedStatus: "idle",
  driverPhone: null,
  isPhoneTokenDecoded: false,
  rawToken: null,
};

export const driverTermsAndPolicyApprovedAsync = createAsyncThunk(
  "driverPolicyApproved/call/",
  async (payload: TermsAndPolicyType) => {
    const phoneToken = localStorage.getItem("phoneToken");
    if (!phoneToken) {
      throw Error(); //TODO
    }
    const axios = axiosInstanceWithPhoneToken(phoneToken);
    await axios.put(`/driver/accept_terms_and_policy`, payload);
  },
);

export const downloadTermsAndConditionsAsync = createAsyncThunk(
  "downloadTermsAndConditions/call/",
  async (payload, thunkAPI) => {
    const axios = axiosInstance();
    const response = await axios.post<GetPresignedUrlType>(
      `/driver_access/get_last_terms_and_conditions`,
    );
    void thunkAPI.dispatch(
      documentDownloadAsync({
        presignedUrl: response.data.presignedUrl,
        fileName: response.data.fileName,
      }),
    );
  },
);

export const downloadPrivacyPolicyAsync = createAsyncThunk(
  "downloadPrivacyPolicy/call/",
  async (payload, thunkAPI) => {
    const axios = axiosInstance();
    const response = await axios.post<GetPresignedUrlType>(
      `/driver_access/get_last_privacy_policy`,
    );
    void thunkAPI.dispatch(
      documentDownloadAsync({
        presignedUrl: response.data.presignedUrl,
        fileName: response.data.fileName,
      }),
    );
  },
);

export const documentDownloadAsync = createAsyncThunk(
  "documentDownload/call/",
  async (payload: { presignedUrl: string; fileName: string }) => {
    if (payload.presignedUrl && payload.fileName) {
      const response = await axios({
        url: payload.presignedUrl,
        method: "GET",
        responseType: "blob",
      });
      const a = document.createElement("a");
      a.download = payload.fileName || "yourDocument.pdf";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
  },
);

export const driverLoginSlice = createSlice({
  name: "driver_login",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    decodePhoneToken: (state) => {
      const phoneToken = localStorage.getItem("phoneToken");
      if (phoneToken) {
        state.rawToken = phoneToken;
        const decodedPhoneToken = jwtDecode<{ phone: string }>(phoneToken);
        state.driverPhone = decodedPhoneToken.phone;
      } else state.driverPhone = null;
      state.isPhoneTokenDecoded = true;
    },
    storeDriverPhone(state, action: PayloadAction<{ phoneInput: string }>) {
      state.driverPhone = action.payload.phoneInput;
    },
    setIsPhoneTokenDecoded(
      state,
      action: PayloadAction<{ isPhoneTokenDecoded: boolean }>,
    ) {
      state.isPhoneTokenDecoded = action.payload.isPhoneTokenDecoded;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(driverTermsAndPolicyApprovedAsync.pending, (state) => {
        state.driverTermsAndPolicyApprovedStatus = "processing";
      })
      .addCase(driverTermsAndPolicyApprovedAsync.fulfilled, (state) => {
        state.driverTermsAndPolicyApprovedStatus = "success";
      })
      .addCase(driverTermsAndPolicyApprovedAsync.rejected, (state) => {
        state.driverTermsAndPolicyApprovedStatus = "failed";
      });
  },
});

export const selectDriverPhone = (state: RootState) =>
  state.driverLogin.driverPhone;
export const selectIsPhoneTokenDecoded = (state: RootState) =>
  state.driverLogin.isPhoneTokenDecoded;
export const selectDriverTermsAndPolicyApprovedStatus = (state: RootState) =>
  state.driverLogin.driverTermsAndPolicyApprovedStatus;

export default driverLoginSlice.reducer;
