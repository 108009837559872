import { Spacer } from "@qivia/ui/src/designSystem/components/Spacer";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { TextCapitalized } from "@qivia/ui/src/designSystem/components/text/TextCapitalized";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  TaskAltOutlined,
  NotInterestedOutlined,
  VisibilityOutlined,
  ContactSupportOutlined,
} from "@qivia/ui/src/designSystem/materialUi/materialUi";
import {
  CardWithRegistrationNumberDriverApp,
  LockedLittleBlackCard,
  Lock,
} from "@qivia/ui/src/assets/assets";
import { useNavigate } from "react-router-dom";
import { RowClickable, RowToggle } from "@qivia/ui";

type PaymentStateType = "AUTHORIZED" | "UNAUTHORIZED";

export interface DriverPaymentPageProps {
  title: string;
  subTitle: string;
  isInOpposition?: boolean;
  isCardLocked?: boolean;
  state: PaymentStateType;
  changeCardStatus: (isCardLocked: boolean) => void;
  disabled: boolean;
  isRotationRequestExceeded: boolean;
}

export const DriverPaymentPage = (props: DriverPaymentPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledPageContainer>
      <StyledHeaderContainer>
        <Spacer x={1} />
        <StyledColumn>
          <StyledIcon $state={props.state}>
            {props.state === "AUTHORIZED" ? (
              <TaskAltOutlined />
            ) : (
              <NotInterestedOutlined />
            )}
          </StyledIcon>
          <Spacer y={1} />
          <StyledTitle>
            <TextCapitalized>{props.title}</TextCapitalized>
          </StyledTitle>
          <Spacer y={0.5} />
          <StyledSubTitle>
            <TextCapitalized>{props.subTitle}</TextCapitalized>
          </StyledSubTitle>
        </StyledColumn>
        <Spacer x={1} />
      </StyledHeaderContainer>
      <StyledAbsolute $isLocked={props.isCardLocked}>
        {props.isCardLocked ? (
          <LockedLittleBlackCard />
        ) : (
          <CardWithRegistrationNumberDriverApp />
        )}
      </StyledAbsolute>

      <StyledFlex>
        <Spacer x={1} />
        <StyledBodyContent>
          <Spacer y={10} />
          {!props.isInOpposition && !props.isRotationRequestExceeded && (
            <>
              <RowClickable
                text={t("driverPayment.pin")}
                icon={<VisibilityOutlined />}
                onClick={() => !props.isCardLocked && navigate("/pin_code")}
              />
              <Spacer y={1} />
              <RowToggle
                onClick={() => props.changeCardStatus(!props.isCardLocked)}
                check={props.isCardLocked ?? false}
                size={"L"}
                text={t(
                  `driverPayment.${props.isCardLocked ? "unlock" : "lock"}`,
                )}
                icon={<Lock />}
                disabled={props.disabled}
              />
              <Spacer y={1} />
            </>
          )}
          <RowClickable
            text={t("driverPayment.support")}
            icon={<ContactSupportOutlined />}
            onClick={() => navigate("/help")}
          />
        </StyledBodyContent>
        <Spacer x={1} />
      </StyledFlex>
      <Spacer y={2} />
    </StyledPageContainer>
  );
};

const StyledFlex = styled.div`
  display: flex;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledBodyContent = styled.div` 
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  ${typographies["Body/M"]}]};
  color: ${colors["colors/text/black"]};
 
`;

const StyledIcon = styled.div<{
  $state: string;
}>`
  & svg {
    width: 3.32rem;
    height: 3.32rem;
    fill: ${({ $state }) =>
      $state === "AUTHORIZED"
        ? colors["colors/accent/600"]
        : colors["colors/system/error/error_normal"]};
  }
  display: flex;
`;

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
`;

const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${colors["colors/text/white"]};
`; //no typo

const StyledSubTitle = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors["colors/text/white"]};
  text-align: center;
`; //no typo

const StyledAbsolute = styled.div<{
  $isLocked?: boolean;
}>`
  position: absolute;
  z-index: 1;
  background-color: transparent;
  justify-content: center;
  display: flex;
  width: 100%;
  padding-top: ${({ $isLocked }) => ($isLocked ? "14.5rem" : "12rem")};
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 17.5rem;
  justify-content: center;
  align-items: center;
  background: linear-gradient(203deg, #1a353a 13.88%, #244841 101.6%);
`;
