import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect } from "react";
import {
  qrCodeAsync,
  selectCardHolderIdentification,
  selectQrCodeId,
  selectQrCodeStatus,
  vehicleLoginSlice,
} from "./vehicleLoginSlice";
import { ErrorPage } from "../components/ErrorPage";
import { LaunchPage } from "@qivia/ui";

export const VehicleLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  const qrCodeId = queryParameters.get("id");
  const qrCodeIdRegister = useAppSelector(selectQrCodeId);
  const cardHolderIdentification = useAppSelector(
    selectCardHolderIdentification,
  );
  const qrCodeStatus = useAppSelector(selectQrCodeStatus);
  const cardHolderIdentificationFromLinkQrCode = useAppSelector(
    selectCardHolderIdentification,
  );

  useEffect(() => {
    if (qrCodeStatus === "success" && qrCodeIdRegister) {
      if (!cardHolderIdentification) {
        navigate("/link_qr_code");
      } else {
        navigate("/driver_login");
      }
    }
  }, [navigate, qrCodeIdRegister, qrCodeStatus, cardHolderIdentification]);

  useEffect(() => {
    if (!cardHolderIdentification && cardHolderIdentificationFromLinkQrCode) {
      dispatch(
        vehicleLoginSlice.actions.setRegistrationNumber({
          cardHolderIdentification: cardHolderIdentificationFromLinkQrCode,
        }),
      );
    }
  }, [
    dispatch,
    cardHolderIdentification,
    cardHolderIdentificationFromLinkQrCode,
  ]);

  useEffect(() => {
    if (qrCodeId && qrCodeStatus === "idle") {
      dispatch(vehicleLoginSlice.actions.setQrCodeId({ qrCodeId }));
      void dispatch(
        qrCodeAsync({
          qrCodeId,
        }),
      );
    }
  }, [
    dispatch,
    qrCodeId,
    qrCodeStatus,
    cardHolderIdentification,
    cardHolderIdentificationFromLinkQrCode,
  ]);

  if ((!qrCodeIdRegister && !qrCodeId) || qrCodeStatus === "failed") {
    return <ErrorPage page="qrCode" />;
  }

  return <LaunchPage />;
};
