import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  driverLoginSlice,
  selectDriverPhone,
  selectIsPhoneTokenDecoded,
} from "./driverLoginSlice";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  LaunchPage,
  PhoneInput,
  Spacer,
  TextCapitalized,
  addPhoneAreaCode,
  hasMobilePhoneFormat,
  phoneNumberFormatter,
} from "@qivia/ui";
import { Page } from "../components/Page";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import {
  qrCodeAsync,
  selectCardHolderIdentification,
  selectQrCodeId,
  selectQrCodeStatus,
  selectQrCodeStored,
  vehicleLoginSlice,
} from "./vehicleLoginSlice";
import { ErrorPage } from "../components/ErrorPage";

export const DriverLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [phone, setPhone] = useState<string>("");
  const isPhoneTokenDecoded = useAppSelector(selectIsPhoneTokenDecoded);
  const driverPhone = useAppSelector(selectDriverPhone);
  const [needDriverPhoneForm, setNeedDriverPhoneForm] =
    useState<boolean>(false);
  const cardHolderIdentification = useAppSelector(
    selectCardHolderIdentification,
  );
  const qrCodeId = useAppSelector(selectQrCodeId);
  const qrCodeStatus = useAppSelector(selectQrCodeStatus);
  const qrCodeStored = useAppSelector(selectQrCodeStored);

  useEffect(() => {
    if (!qrCodeId) {
      void dispatch(vehicleLoginSlice.actions.getQrCodeId());
    }
  }, [dispatch, qrCodeId]);

  useEffect(() => {
    if (!cardHolderIdentification && qrCodeId) {
      void dispatch(qrCodeAsync({ qrCodeId }));
    }
  }, [dispatch, qrCodeId, cardHolderIdentification]);

  useEffect(() => {
    if (!isPhoneTokenDecoded)
      dispatch(driverLoginSlice.actions.decodePhoneToken());
  }, [dispatch, isPhoneTokenDecoded]);

  useEffect(() => {
    if (isPhoneTokenDecoded && !driverPhone) setNeedDriverPhoneForm(true);
  }, [dispatch, driverPhone, isPhoneTokenDecoded]);

  useEffect(() => {
    if (isPhoneTokenDecoded && driverPhone && cardHolderIdentification)
      navigate("/driver_known");
  }, [driverPhone, isPhoneTokenDecoded, navigate, cardHolderIdentification]);

  if (qrCodeStatus === "failed" || (!qrCodeId && qrCodeStored))
    return <ErrorPage page="qrCode" />;

  if (needDriverPhoneForm) {
    return (
      <DriverPhone
        phone={phone}
        setPhone={setPhone}
        followingPage={() => {
          dispatch(
            driverLoginSlice.actions.storeDriverPhone({
              phoneInput: addPhoneAreaCode(phone),
            }),
          );
          navigate("/driver_phone_auth");
        }}
      />
    );
  }
  return <LaunchPage />;
};

const DriverPhone = (props: {
  phone: string;
  setPhone: (value: string) => void;
  followingPage: () => void;
}) => {
  const { t } = useTranslation();
  const { phone, followingPage } = props;

  const [displayError, setDisplayError] = useState<boolean>(false);

  const clickToSubmit = useCallback(() => {
    if (phone === "" || !hasMobilePhoneFormat(phone)) {
      setDisplayError(true);
    } else {
      setDisplayError(false);

      followingPage();
    }
  }, [phone, followingPage]);

  return (
    <Page
      title={
        <StyledTitle>
          <TextCapitalized>{t(`driverLogin.phone.title`)}</TextCapitalized>
          <TextCapitalized>{t(`driverLogin.phone.subTitle`)}</TextCapitalized>
        </StyledTitle>
      }
      bottomButton={
        <Button
          title={t("driverLogin.button.next")}
          onClick={clickToSubmit}
          height={3.5}
          fontSize={1.1}
          disabled={props.phone === "" || !hasMobilePhoneFormat(props.phone)}
        />
      }
      helpButton
    >
      <StyledContainer>
        <Spacer y={1} />
        <StyledBody>
          <Spacer x={0.25} />
          <TextCapitalized>{t("driverLogin.body") || ""}</TextCapitalized>
          <Spacer x={0.25} />
        </StyledBody>
        <Spacer y={2} />
        <PhoneInput
          label={t("driverLogin.phone.label") || ""}
          placeholder={t("driverLogin.phone.placeholder") || ""}
          onChange={(e) => {
            props.setPhone(phoneNumberFormatter(e.target.value));
          }}
          value={phoneNumberFormatter(props.phone)}
          error={
            displayError && props.phone === ""
              ? t("driverLogin.error.input") || ""
              : displayError && !hasMobilePhoneFormat(props.phone)
                ? t("driverLogin.error.incorrectPhoneFormat") || ""
                : undefined
          }
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
        />
        <Spacer y={2} />
      </StyledContainer>
    </Page>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledBody = styled.div`
  display: flex;
  width: 100%;
  color: ${colors["colors/text/black"]};
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
`;
