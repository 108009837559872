import { LaunchPage, Spacer, TextCapitalized } from "@qivia/ui";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect, useState } from "react";
import {
  displayPinCodeAsync,
  displayPinCodeSlice,
  getProviderInfoAsync,
  selectAccountProvider,
  selectCardProvider,
  selectDisplayPinCodeStatus,
  selectEphemeralKey,
  selectGetProviderInfoStatus,
  selectNonce,
} from "./displayPinCodeSlice";
import { ErrorPage } from "../components/ErrorPage";
import { selectCardHolderIdentification } from "./vehicleLoginSlice";
import { colors } from "@qivia/ui/src/styles/figmaColors";

export const DisplayPinCode = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [stripeLoaded, setStripeLoaded] = useState<Stripe | null>(null);

  const cardHolderIdentification = useAppSelector(
    selectCardHolderIdentification,
  );
  const ephemeralKey = useAppSelector(selectEphemeralKey);
  const nonce = useAppSelector(selectNonce);
  const cardProvider = useAppSelector(selectCardProvider);
  const accountProvider = useAppSelector(selectAccountProvider);
  const getProviderInfoStatus = useAppSelector(selectGetProviderInfoStatus);
  const displayPinCodeStatus = useAppSelector(selectDisplayPinCodeStatus);

  useEffect(() => {
    setStripeLoaded(null);
    void dispatch(displayPinCodeSlice.actions.resetEphemeralKey());
    void dispatch(displayPinCodeSlice.actions.resetNonce());
  }, [dispatch, location]);

  useEffect(() => {
    if (cardHolderIdentification && getProviderInfoStatus === "idle") {
      void dispatch(getProviderInfoAsync(cardHolderIdentification));
    }
  }, [cardHolderIdentification, getProviderInfoStatus, dispatch]);

  useEffect(() => {
    if (accountProvider && getProviderInfoStatus === "success") {
      void loadStripe(
        `${import.meta.env["VITE_STRIPE_PUBLIC_KEY"] as string}`,
        {
          apiVersion: "2022-11-15",
          stripeAccount: accountProvider,
        },
      ).then(setStripeLoaded);
    }
  }, [accountProvider, getProviderInfoStatus]);

  useEffect(() => {
    if (!cardProvider || !stripeLoaded) return;
    void stripeLoaded
      .createEphemeralKeyNonce({
        issuingCard: cardProvider,
      })
      .then(
        (keyNounce) =>
          keyNounce.nonce &&
          void dispatch(
            displayPinCodeSlice.actions.storeNonce({
              nonce: keyNounce.nonce,
            }),
          ),
      );
  }, [cardProvider, dispatch, stripeLoaded]);

  useEffect(() => {
    if (!nonce || !cardProvider || !accountProvider || !stripeLoaded) return;
    void dispatch(
      displayPinCodeAsync({ nonce, cardProvider, accountProvider }),
    );
  }, [accountProvider, cardProvider, dispatch, nonce, stripeLoaded]);

  useEffect(() => {
    if (
      !nonce ||
      !cardProvider ||
      !ephemeralKey ||
      !stripeLoaded ||
      displayPinCodeStatus !== "success"
    )
      return;

    const number = stripeLoaded.elements().create("issuingCardPinDisplay", {
      issuingCard: cardProvider,
      nonce: `${nonce || ""}`,
      ephemeralKeySecret: `${ephemeralKey}`,
      style: {
        base: {
          fontSize: "35px",
          letterSpacing: "15px",
          lineHeight: "55px",
        },
      },
    });
    number.mount("#card-pin");
  }, [cardProvider, displayPinCodeStatus, ephemeralKey, nonce, stripeLoaded]);

  if (
    !cardHolderIdentification ||
    getProviderInfoStatus === "failed" ||
    displayPinCodeStatus === "failed"
  ) {
    return <ErrorPage page="qrCode" />;
  }

  if (
    getProviderInfoStatus !== "success" ||
    displayPinCodeStatus !== "success" ||
    !nonce ||
    !ephemeralKey ||
    !stripeLoaded
  ) {
    return <LaunchPage />;
  }

  return (
    <StyledPageContainer>
      <Spacer y={2.5} />
      <StyledHeaderContainer>
        <StyledHelpButton onClick={() => navigate("/help")}>
          <TextCapitalized>{t("page.button.help")}</TextCapitalized>
        </StyledHelpButton>
        <Spacer x={2} />
      </StyledHeaderContainer>
      <StyledContent>
        <Spacer x={2} />
        <StyledBody>
          <StyledTitle>
            <TextCapitalized>{t("pinCode.title")}</TextCapitalized>
            {cardHolderIdentification}
          </StyledTitle>
          <Spacer y={1.5} />
          <StyledPinContainer>
            <Spacer x={1} />
            <StyledPin>
              <div id="card-pin"></div>
            </StyledPin>
            <Spacer x={1} />
          </StyledPinContainer>
          <Spacer y={2} />
          <TextCapitalized>{t("pinCode.content")}</TextCapitalized>
          <Spacer y={6} />
        </StyledBody>
        <Spacer x={2} />
      </StyledContent>
    </StyledPageContainer>
  );
};
const StyledPin = styled.div`
  width: 8rem;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Inter";
  height: 3.3rem;
`;

const StyledPinContainer = styled.div`
  background-color: ${colors["colors/text/white"]};
  border-radius: 0.5rem;
  display: flex;
`;

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100%;
  font-family: "Inter";
  font-size: 1rem;
  font-style: normal;
  background-color: #f5f8f7;
  color: #535654;
`;

const StyledContent = styled.div`
  height: 100%;
  display: flex;
  overflow: auto;
  text-align: center;
`;

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors["colors/text/black"]};
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  min-width: 100%;
  justify-content: flex-end;
`;

const StyledHelpButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.25rem;
  height: 1.75rem;
  border-radius: 1.75rem;
  font-weight: 500;
  font-size: 0.75rem;
  color: ${colors["colors/text/black"]};
  background-color: ${colors["colors/text/white"]};
  cursor: pointer;
`;
