import styled from "styled-components";
import { Spacer, TextCapitalized, TextClickable } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import {
  downloadPrivacyPolicyAsync,
  downloadTermsAndConditionsAsync,
} from "./driverLoginSlice";
import { useAppDispatch } from "../../redux/hooks";

const TermsAndPolicySentence = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <StyledSentence>
      <TextCapitalized>
        {t(`driverLogin.termsAndPolicy.beginning`)}
      </TextCapitalized>
      <Spacer x={0.25} />
      <TextClickable
        onClick={() => void dispatch(downloadTermsAndConditionsAsync())}
        capitalized
        underline
      >
        {t(`driverLogin.termsAndPolicy.terms`)}
      </TextClickable>
      <Spacer x={0.25} />
      {t(`driverLogin.termsAndPolicy.middle`)}
      <Spacer x={0.25} />
      <TextClickable
        onClick={() => void dispatch(downloadPrivacyPolicyAsync())}
        capitalized
        underline
      >
        {t(`driverLogin.termsAndPolicy.policy`)}
      </TextClickable>

      {t(`driverLogin.termsAndPolicy.end`)}
    </StyledSentence>
  );
};

export default TermsAndPolicySentence;

const StyledSentence = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
