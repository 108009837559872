import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  HelpFilled,
  NumericInput,
  Spacer,
  TextCapitalized,
  TooltipComponent,
} from "@qivia/ui";
import { Dispatch, SetStateAction } from "react";

interface QuantityInputProps {
  updateTransactionDataAsync?: () => void;
  quantity: number | "";
  setQuantity: Dispatch<SetStateAction<number | "">>;
  onKeyDownAction: () => void;
  error?: string;
  tooltipColor?: string;
}

export const QuantityInput = (props: QuantityInputProps) => {
  const { t } = useTranslation();
  return (
    <NumericInput
      id="quantity"
      update={props.setQuantity}
      value={props.quantity}
      floatNumber
      label={
        <StyledLabelTooltip $tooltipColor={props.tooltipColor}>
          <TextCapitalized>{t("quantity")}</TextCapitalized>
          <Spacer x={0.5} />
          <HelpFilled data-tooltip-id="QuantityTooltip" />
          <TooltipComponent
            id={"QuantityTooltip"}
            place={"top"}
            title={t(`quantity`) ?? ""}
            content={t(`quantity.tooltip.content`) ?? ""}
          />
        </StyledLabelTooltip>
      }
      placeholder={"0,0000"}
      endText={t("quantity.units") ?? ""}
      onBlur={() => props.updateTransactionDataAsync?.()}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          props.updateTransactionDataAsync?.();
          props.onKeyDownAction();
        }
      }}
      error={props.error}
    />
  );
};

const StyledLabelTooltip = styled.div<{ $tooltipColor?: string }>`
  display: flex;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
    fill: ${({ $tooltipColor }) => ($tooltipColor ? $tooltipColor : "#3CD3AD")};
    &:focus {
      outline: none;
    }
  }
`;
