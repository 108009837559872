import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstance } from "../../utils";
import { DisplayPinCodeType, ProviderDataType } from "./displayPinCodeApi";

export interface displayPinCodeState {
  displayPinCodeStatus: QueryStatus;
  getProviderInfoStatus: QueryStatus;
  nonce: string | null;
  ephemeralKey: string | null;
  cardProvider: string | null;
  accountProvider: string | null;
}

const initialState: displayPinCodeState = {
  displayPinCodeStatus: "idle",
  getProviderInfoStatus: "idle",
  nonce: null,
  ephemeralKey: null,
  cardProvider: null,
  accountProvider: null,
};

export const displayPinCodeAsync = createAsyncThunk(
  "displayPinCode/call/",
  async (payload: DisplayPinCodeType) => {
    const axios = axiosInstance();
    const response = await axios.post<{ ephemeralKey: string }>(
      `/driver_access/stripe/get_ephemeral_key`,
      payload,
    );
    return response.data;
  },
);

export const getProviderInfoAsync = createAsyncThunk(
  "getProviderInfo/call/",
  async (cardHolderIdentification: string) => {
    const axios = axiosInstance();
    const response = await axios.get<ProviderDataType>(
      `/driver_access/vehicle/${cardHolderIdentification}/get_provider_data`,
    );
    return response.data;
  },
);

export const displayPinCodeSlice = createSlice({
  name: "display_pin_code",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetEphemeralKey(state) {
      state.ephemeralKey = null;
    },
    resetNonce(state) {
      state.nonce = null;
    },
    storeNonce(state, action: PayloadAction<{ nonce: string }>) {
      state.nonce = action.payload.nonce;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(displayPinCodeAsync.pending, (state) => {
        state.displayPinCodeStatus = "processing";
      })
      .addCase(displayPinCodeAsync.fulfilled, (state, action) => {
        state.displayPinCodeStatus = "success";
        state.ephemeralKey = action.payload.ephemeralKey;
      })
      .addCase(displayPinCodeAsync.rejected, (state) => {
        state.displayPinCodeStatus = "failed";
      })
      .addCase(getProviderInfoAsync.pending, (state) => {
        state.getProviderInfoStatus = "processing";
      })
      .addCase(getProviderInfoAsync.fulfilled, (state, action) => {
        state.getProviderInfoStatus = "success";
        state.cardProvider = action.payload.cardProvider;
        state.accountProvider = action.payload.accountProvider;
      })
      .addCase(getProviderInfoAsync.rejected, (state) => {
        state.getProviderInfoStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectNonce = (state: RootState) => state.displayPinCode.nonce;

export const selectEphemeralKey = (state: RootState) =>
  state.displayPinCode.ephemeralKey;

export const selectCardProvider = (state: RootState) =>
  state.displayPinCode.cardProvider;

export const selectAccountProvider = (state: RootState) =>
  state.displayPinCode.accountProvider;

export const selectGetProviderInfoStatus = (state: RootState) =>
  state.displayPinCode.getProviderInfoStatus;

export const selectDisplayPinCodeStatus = (state: RootState) =>
  state.displayPinCode.displayPinCodeStatus;

export default displayPinCodeSlice.reducer;
