import {
  Button,
  Spacer,
  colors,
  NumericInput,
  TextCapitalized,
  triggerToast,
  ToastContainer,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Page } from "../components/Page";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import {
  requestValidationCodeAsync,
  selectRequestValidationCodeStatus,
  selectVerifyValidationCodeStatus,
  verifyValidationCodeAsync,
} from "./driverPhoneAuthSlice";
import { selectDriverPhone } from "./driverLoginSlice";
import { ErrorPage } from "../components/ErrorPage";

export const DriverPhoneAuth = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const driverPhone = useAppSelector(selectDriverPhone);

  const [is6Digits, setIs6Digits] = useState<boolean>(false);
  const [code, setCode] = useState<number | "">("");
  const verifyValidationCodeStatus = useAppSelector(
    selectVerifyValidationCodeStatus,
  );
  const requestValidationCodeStatus = useAppSelector(
    selectRequestValidationCodeStatus,
  );
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);

  const clickToSubmit = useCallback(() => {
    if (is6Digits) {
      driverPhone &&
        void dispatch(
          verifyValidationCodeAsync({
            phone: driverPhone,
            code: code.toString(),
          }),
        );
      setDisplayError(false);
    } else {
      setDisplayError(true);
    }
  }, [code, dispatch, driverPhone, is6Digits, setDisplayError]);

  const onClickResendCode = useCallback(() => {
    if (!driverPhone || isResendButtonDisabled) return;
    void dispatch(requestValidationCodeAsync({ phone: driverPhone }));
    setIsResendButtonDisabled(true);
    setTimeout(() => {
      setIsResendButtonDisabled(false);
    }, 5000);
  }, [dispatch, driverPhone, isResendButtonDisabled]);

  useEffect(() => {
    if (
      requestValidationCodeStatus === "success" &&
      driverPhone &&
      isResendButtonDisabled
    )
      triggerToast(t("driverPhoneAuth.button.resend.success") || "", "valid");
  }, [
    dispatch,
    driverPhone,
    isResendButtonDisabled,
    requestValidationCodeStatus,
    t,
  ]);

  useEffect(() => {
    if (requestValidationCodeStatus === "idle" && driverPhone)
      void dispatch(requestValidationCodeAsync({ phone: driverPhone }));
  }, [dispatch, driverPhone, requestValidationCodeStatus]);

  useEffect(() => {
    setIs6Digits(code.toString().length === 6);
  }, [code]);

  useEffect(() => {
    if (verifyValidationCodeStatus === "success") {
      navigate("/driver_known");
    } else if (verifyValidationCodeStatus === "failed") {
      setDisplayError(true);
    }
  }, [navigate, verifyValidationCodeStatus]);

  if (requestValidationCodeStatus === "failed" || driverPhone === null)
    return <ErrorPage page="qrCode" />;

  return (
    <Page
      title={t(`driverPhoneAuth.title`)}
      bottomButton={
        <StyledContainer>
          <Spacer y={1.5} />
          <Button
            title={t("driverPhoneAuth.button.next")}
            onClick={clickToSubmit}
            height={3.5}
            fontSize={1.1}
            disabled={!is6Digits}
          />
        </StyledContainer>
      }
      helpButton
    >
      <ToastContainer />
      <StyledContainer>
        <Spacer y={1} />
        <StyledBody>
          <Spacer x={0.25} />
          <TextCapitalized>{t("driverPhoneAuth.body") || ""}</TextCapitalized>
          <Spacer x={0.25} />
        </StyledBody>
        <Spacer y={2} />
        <NumericInput
          label={t("driverPhoneAuth.label") || ""}
          placeholder={t("driverPhoneAuth.placeholder") || ""}
          value={code}
          update={setCode}
          noneBottomSpacer
          error={
            (displayError &&
              ((!is6Digits && t("driverPhoneAuth.6Digits.error")) ||
                "" ||
                (verifyValidationCodeStatus === "failed" &&
                  t("driverPhoneAuth.input.error")) ||
                "")) ||
            undefined
          }
          maxDigits={6}
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
        />
        <Button
          title={t("driverPhoneAuth.button.resend")}
          underline
          withoutBackground
          width={9}
          noColorHover
          color={isResendButtonDisabled ? colors.disabled : colors.primary}
          fontWeight={400}
          onClick={onClickResendCode}
          disabled={isResendButtonDisabled}
        />
      </StyledContainer>
    </Page>
  );
};

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledBody = styled.div`
  display: flex;
  width: 100%;
  color: ${colors.black};
`;
