import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  HelpFilled,
  NumericInput,
  Spacer,
  TextCapitalized,
  TooltipComponent,
} from "@qivia/ui";
import { Dispatch, SetStateAction } from "react";

interface MileageInputProps {
  updateTransactionDataAsync: () => void;
  mileage: number | "";
  setMileage: Dispatch<SetStateAction<number | "">>;
  onKeyDownAction?: () => void;
  error?: string;
  tooltipColor?: string;
}

export const MileageInput = (props: MileageInputProps) => {
  const { t } = useTranslation();

  return (
    <NumericInput
      id="mileage"
      update={props.setMileage}
      value={props.mileage}
      label={
        <StyledLabelTooltip $tooltipColor={props.tooltipColor}>
          <TextCapitalized>{t("mileage")}</TextCapitalized>
          <Spacer x={0.5} />
          <HelpFilled data-tooltip-id="MileageTooltip" />
          <TooltipComponent
            id={"MileageTooltip"}
            place={"top"}
            title={t(`mileage`) ?? ""}
            content={t(`mileage.tooltip.content`) ?? ""}
          />
        </StyledLabelTooltip>
      }
      placeholder={"0"}
      endText={t("mileage.units") ?? ""}
      onBlur={() => props.updateTransactionDataAsync()}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          props.updateTransactionDataAsync();
          props.onKeyDownAction?.();
        }
      }}
      error={props.error}
    />
  );
};

const StyledLabelTooltip = styled.div<{ $tooltipColor?: string }>`
  display: flex;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
    fill: ${({ $tooltipColor }) => ($tooltipColor ? $tooltipColor : "#3CD3AD")};
    &:focus {
      outline: none;
    }
  }
`;
