import styled from "styled-components";
import {
  Spacer,
  Button,
  Assets,
  hasRegistrationNumberFormat,
  Input,
  registrationNumberFormatter,
  TextCapitalized,
  hasIdentifierFormat,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { Page } from "../components/Page";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { ErrorPage } from "../components/ErrorPage";
import {
  selectQrCodeId,
  selectQrCodeStatus,
  selectQrCodeStored,
  vehicleLoginSlice,
} from "./vehicleLoginSlice";
import {
  linkQrCodeAsync,
  selectLinkQrCodeError,
  selectLinkQrCodeStatus,
} from "./linkQrCodeSlice";

export const LinkQrCodeForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [cardHolderIdentification, setCardHolderIdentification] =
    useState<string>("");
  const [errorIdentifier, setErrorIdentifier] = useState<boolean>(false);

  const linkQrCodeStatus = useAppSelector(selectLinkQrCodeStatus);
  const linkQrCodeError = useAppSelector(selectLinkQrCodeError);
  const qrCodeId = useAppSelector(selectQrCodeId);
  const qrCodeStored = useAppSelector(selectQrCodeStored);
  const qrCodeStatus = useAppSelector(selectQrCodeStatus);

  const findCardHolder = useCallback(
    (cardHolderIdentification: string): string | undefined => {
      const cardHolderSplittedWithSlash =
        cardHolderIdentification.split("/")[0];
      const cardHolderSplittedWithSpace =
        cardHolderIdentification.split(" ")[0];
      const cardHolders = [
        cardHolderSplittedWithSlash,
        cardHolderSplittedWithSpace,
        cardHolderIdentification,
      ];

      return cardHolders.find((cardHolder) => {
        return (
          hasRegistrationNumberFormat(cardHolder) ||
          hasIdentifierFormat(cardHolder)
        );
      });
    },
    [],
  );

  const hasCardHolderFormat = useCallback(
    (cardHolderIdentification: string): boolean => {
      return !!findCardHolder(cardHolderIdentification);
    },
    [findCardHolder],
  );

  const formatCardHolder = useCallback(
    (cardHolderIdentification: string) => {
      const cardHolder = findCardHolder(cardHolderIdentification);
      if (!cardHolder) return "";
      if (hasRegistrationNumberFormat(cardHolder))
        return registrationNumberFormatter(cardHolder);
      else return cardHolder;
    },
    [findCardHolder],
  );

  useEffect(() => {
    if (!qrCodeId) {
      void dispatch(vehicleLoginSlice.actions.getQrCodeId());
    }
  }, [dispatch, qrCodeId]);

  const clickToSubmit = useCallback(() => {
    hasCardHolderFormat(cardHolderIdentification)
      ? void dispatch(
          linkQrCodeAsync({
            cardHolderIdentification: formatCardHolder(
              cardHolderIdentification,
            ),
            qrCodeId,
          }),
        )
      : setErrorIdentifier(true);
  }, [
    cardHolderIdentification,
    dispatch,
    formatCardHolder,
    hasCardHolderFormat,
    qrCodeId,
  ]);

  useEffect(() => {
    if (linkQrCodeStatus === "success" && linkQrCodeError === "") {
      navigate("/driver_login");
    }
  }, [linkQrCodeError, linkQrCodeStatus, navigate]);

  if (
    (!qrCodeId && qrCodeStored) ||
    (qrCodeStatus === "idle" && qrCodeId && qrCodeStored) ||
    linkQrCodeStatus === "failed" ||
    linkQrCodeError === "Vehicle not in circulation"
  ) {
    return (
      <ErrorPage
        page={
          linkQrCodeError === "Vehicle not in circulation"
            ? "vehicleNotInCirculation"
            : "activateCard"
        }
      />
    );
  }

  return (
    <Page
      title={t("linkQrCode.title")}
      bottomButton={
        <Button
          title={t("linkQrCode.button")}
          height={3.5}
          fontSize={1.1}
          disabled={!hasCardHolderFormat(cardHolderIdentification)}
          onClick={clickToSubmit}
        />
      }
      helpButton
      withoutHeaderSpacer
    >
      <StyledContainer>
        <Spacer y={1.5} />
        <StyledCardLogo>
          <Assets.CardWithCardHolderIdentification />
        </StyledCardLogo>
        <Spacer y={1.5} />
        <StyledInputTitle>
          <TextCapitalized>{t("linkQrCode.input.title")}</TextCapitalized>
        </StyledInputTitle>
        <Spacer y={0.5} />
        <Input
          title={t("linkQrCode.input") || ""}
          placeholder={t("linkQrCode.placeholder") || ""}
          value={cardHolderIdentification}
          onChange={(e) => setCardHolderIdentification(e.target.value)}
          isPlaceholderUppercase
          error={
            errorIdentifier && !hasCardHolderFormat(cardHolderIdentification)
              ? t("linkQrCode.input.error.incorrectFormat") || ""
              : linkQrCodeError === "No vehicle found"
                ? t("linkQrCode.input.error.vehicleNotFound") || ""
                : undefined
          }
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
        />
        <Spacer y={0.5} />
        <TextCapitalized>{t("linkQrCode.text")}</TextCapitalized>
      </StyledContainer>
    </Page>
  );
};

const StyledInputTitle = styled.div`
  display: flex;
  font-weight: 500;
  color: ${colors["colors/text/black"]};
`;

const StyledCardLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
