import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { UploadSupportingDocumentButton } from "../supportingDocuments/uploadSupportingDocumentButton";
import { Assets, Button, Spacer, TextCapitalized } from "@qivia/ui";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getSupportingDocument,
  selectSupportingDocumentPresignedUrl,
  selectUploadSupportingDocumentToS3Status,
  supportingDocumentDownloadAsync,
} from "../supportingDocuments/uploadSupportingDocumentSlice";
import {
  selectHistoryTransactionDataStatus,
  transactionHistoryDataAsync,
} from "../transactions/transactionDataFormSlice";
import { useEffect } from "react";
import { colors } from "@qivia/ui/src/styles/figmaColors";

export interface SupportingDocumentProps {
  transactionId: string;
  supportingDocumentName: string | null;
  supportingDocumentId: string | null;
}

export const SupportingDocument = (props: SupportingDocumentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const uploadSupportingDocumentToS3Status = useAppSelector(
    selectUploadSupportingDocumentToS3Status,
  );

  const historyTransactionDataStatus = useAppSelector(
    selectHistoryTransactionDataStatus,
  );

  const presignedUrl = useAppSelector(selectSupportingDocumentPresignedUrl);

  useEffect(() => {
    if (presignedUrl && props.supportingDocumentName) {
      void dispatch(
        supportingDocumentDownloadAsync({
          presignedUrl,
          fileName: props.supportingDocumentName,
        }),
      );
    }
  }, [presignedUrl, dispatch, props.supportingDocumentName]);

  useEffect(() => {
    if (
      ["success", "error"].includes(uploadSupportingDocumentToS3Status) &&
      props.transactionId
    )
      void dispatch(transactionHistoryDataAsync(props.transactionId));
  }, [dispatch, props.transactionId, uploadSupportingDocumentToS3Status]);

  return (
    <>
      <StyledLabel>
        <TextCapitalized>
          {t("supportingDocument.component.label" as const) || ""}
        </TextCapitalized>
      </StyledLabel>
      <StyledSupportingDocument>
        {props.supportingDocumentName ? (
          <>
            <StyledBrokenImage>
              <Assets.BrokenImage
                onClick={() =>
                  props.supportingDocumentId &&
                  void dispatch(
                    getSupportingDocument(props.supportingDocumentId),
                  )
                }
              />
            </StyledBrokenImage>
            <Spacer x={1} />
            <StyledBody>
              <StyledText>{props.supportingDocumentName}</StyledText>
              <Spacer y={0.25} />
              {historyTransactionDataStatus === "success" ? (
                <StyledDocumentInfo>
                  <TextCapitalized>
                    {t("supportingDocument.component.success" as const) || ""}
                  </TextCapitalized>
                  <Spacer x={0.5} />
                  <Assets.UploadSuccess />
                </StyledDocumentInfo>
              ) : (
                <StyledDocumentInfo>
                  <TextCapitalized>
                    {t("supportingDocument.component.error" as const) || ""}
                  </TextCapitalized>
                  <Spacer x={0.5} />
                  <Assets.UploadError />
                </StyledDocumentInfo>
              )}
            </StyledBody>
            <Spacer x={1} />
            <UploadSupportingDocumentButton transactionId={props.transactionId}>
              <StyledReloadDocument>
                <Assets.ReloadArrows />
              </StyledReloadDocument>
            </UploadSupportingDocumentButton>
          </>
        ) : (
          <StyledEmptySupportingDocument>
            <StyledRow>
              <Spacer x={1} />
              <Assets.UploadError />
              <Spacer x={0.5} />
              <TextCapitalized>
                {t("supportingDocument.component.empty" as const) || ""}
              </TextCapitalized>
            </StyledRow>
            <Spacer x={0.5} />
            <UploadSupportingDocumentButton transactionId={props.transactionId}>
              <Button
                title={t("supportingDocument.button.add" as const)}
                width={5}
              />
            </UploadSupportingDocumentButton>
            <Spacer x={1} />
          </StyledEmptySupportingDocument>
        )}
      </StyledSupportingDocument>
    </>
  );
};
const StyledEmptySupportingDocument = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 0.0625rem solid ${colors["colors/borders/input/default"]};
  border-radius: 0.5rem;
  height: 3.3rem;
  color: ${colors["colors/system/error/error_normal"]};
  font-size: 0.9rem;
  font-weight: 500;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledSupportingDocument = styled.div`
  display: flex;
  align-items: center;
  height: 4rem;
  width: 100%;
  word-break: break-all;
`;

const StyledReloadDocument = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.25rem;
  height: 2rem;
  background-color: ${colors["colors/surfaces/background/background_level1"]};
  border-radius: 1.75rem;
  cursor: pointer;
`;

const StyledBrokenImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.75rem;
  height: 2.5rem;
  background-color: ${colors["colors/surfaces/background/background_level1"]};
  border-radius: 0.5rem;
`;

const StyledText = styled.div`
  font-weight: 500;
`;

const StyledBody = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  width: 100%;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  font-family: Inter;
  color: ${colors["colors/text/darkGrey"]};
  margin-bottom: 0.5rem;
`;

const StyledDocumentInfo = styled.div`
  display: flex;
  max-height: 1.2rem;
  align-items: center;
`;
