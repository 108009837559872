import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstanceWithPhoneToken } from "../../utils";
import { DriverRegisterDBType } from "./driverRegisterApi";

export interface driverRegisterState {
  driverRegisterStatus: QueryStatus;
}

const initialState: driverRegisterState = {
  driverRegisterStatus: "idle",
};

export const driverRegisterAsync = createAsyncThunk(
  "driverRegister/call/",
  async (payload: DriverRegisterDBType) => {
    const phoneToken = localStorage.getItem("phoneToken");
    if (!phoneToken) {
      throw Error(); //TODO add try catch and display correct error in front
    }
    const axios = axiosInstanceWithPhoneToken(phoneToken);
    await axios.put(`/driver/create`, payload);
  },
);

export const driverRegisterSlice = createSlice({
  name: "driver_register",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(driverRegisterAsync.pending, (state) => {
        state.driverRegisterStatus = "processing";
      })
      .addCase(driverRegisterAsync.fulfilled, (state) => {
        state.driverRegisterStatus = "success";
      })
      .addCase(driverRegisterAsync.rejected, (state) => {
        state.driverRegisterStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectDriverRegisterStatus = (state: RootState) =>
  state.driverRegister.driverRegisterStatus;

export default driverRegisterSlice.reducer;
