import {
  Button,
  Input,
  Spacer,
  capitalize,
  LaunchPage,
  TextCapitalized,
  Checkbox,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Page } from "../components/Page";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  driverRegisterAsync,
  selectDriverRegisterStatus,
} from "./driverRegisterSlice";
import { selectDriverPhone } from "./driverLoginSlice";
import { ErrorPage } from "../components/ErrorPage";
import {
  selectCardHolderIdentification,
  selectQrCodeId,
} from "./vehicleLoginSlice";
import TermsAndPolicySentence from "./termsAndPolicySentence";
import { colors } from "@qivia/ui/src/styles/figmaColors";

export const DriverRegister = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const driverRegisterStatus = useAppSelector(selectDriverRegisterStatus);
  const qrCodeId = useAppSelector(selectQrCodeId);
  const cardHolderIdentification = useAppSelector(
    selectCardHolderIdentification,
  );
  const driverPhone = useAppSelector(selectDriverPhone);

  useEffect(() => {
    if (driverRegisterStatus !== "success") return;
    navigate("/menu");
  }, [driverRegisterStatus, navigate]);

  const registerDriver = useCallback(() => {
    if (!cardHolderIdentification) return;
    void dispatch(
      driverRegisterAsync({
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        cardHolderIdentification,
      }),
    );
  }, [dispatch, firstName, lastName, cardHolderIdentification]);

  if (
    driverPhone === null ||
    !qrCodeId ||
    !cardHolderIdentification ||
    driverRegisterStatus === "failed"
  ) {
    return <ErrorPage page="qrCode" />;
  }
  if (driverRegisterStatus === "processing") {
    return <LaunchPage />;
  }

  return (
    <DriverName
      phone={driverPhone}
      firstName={firstName}
      lastName={lastName}
      setFirstName={setFirstName}
      setLastName={setLastName}
      followingPage={registerDriver}
      termsAndPolicySentence={<TermsAndPolicySentence />}
    />
  );
};

const DriverName = (props: {
  phone: string;
  firstName: string;
  lastName: string;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  followingPage: () => void;
  termsAndPolicySentence: JSX.Element;
}) => {
  const { firstName, lastName, followingPage } = props;
  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const isFormInvalid = useMemo(() => {
    return firstName === "" || lastName === "" || !isChecked;
  }, [firstName, lastName, isChecked]);

  const clickToSubmit = useCallback(() => {
    if (isFormInvalid) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      followingPage();
    }
  }, [isFormInvalid, followingPage]);

  return (
    <Page
      title={t(`driverLogin.name.title`)}
      bottomButton={
        <Button
          title={t("driverLogin.button.next")}
          onClick={clickToSubmit}
          height={3.5}
          fontSize={1.1}
          disabled={isFormInvalid}
        />
      }
      helpButton
    >
      <StyledContainer>
        <Spacer y={1} />
        <StyledBody>
          <Spacer x={0.25} />
          <TextCapitalized>{t("driverLogin.body") || ""}</TextCapitalized>
          <Spacer x={0.25} />
        </StyledBody>
        <Spacer y={2} />
        <Input
          label={t("driverLogin.name.label.firstName") || ""}
          placeholder={t("driverLogin.name.placeholder.firstName") || ""}
          defaultValue={props.firstName}
          onChange={(e) => props.setFirstName(e.target.value)}
          error={
            (displayError &&
              props.firstName === "" &&
              t("driverLogin.error.input")) ||
            undefined
          }
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
        />
        <Input
          label={t("driverLogin.name.label.lastName") || ""}
          placeholder={t("driverLogin.name.placeholder.lastName") || ""}
          defaultValue={props.lastName}
          onChange={(e) => props.setLastName(e.target.value)}
          error={
            (displayError &&
              props.lastName === "" &&
              t("driverLogin.error.input")) ||
            undefined
          }
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
        />
        <StyledBottom>
          <Spacer x={0.5} />
          <Checkbox
            borderColor={
              isChecked ? "transparent" : colors["colors/text/darkGrey"]
            }
            size={1}
            text={props.termsAndPolicySentence}
            check={isChecked}
            onClick={() => setIsChecked(!isChecked)}
          />
          <Spacer x={0.5} />
        </StyledBottom>
        {displayError && !isChecked && (
          <StyledErrorMessage>
            <Spacer y={1} />
            <TextCapitalized>
              {t("driverLogin.termsAndPolicy.error")}
            </TextCapitalized>
          </StyledErrorMessage>
        )}
        <Spacer y={2} />
      </StyledContainer>
    </Page>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledBody = styled.div`
  display: flex;
  width: 100%;
  color: ${colors["colors/text/black"]};
`;

const StyledBottom = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

const StyledErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors["colors/system/error/error_normal"]};
`;
