import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  transactionDataFormSlice,
  selectUpdateTransactionDataStatus,
  updateTransactionDataAsync,
  selectTransactionData,
  selectHistoryTransactionDataStatus,
} from "./transactionDataFormSlice";
import {
  Button,
  DriverLoadingPage,
  DriverSuccessPage,
  Spacer,
  TextCapitalized,
} from "@qivia/ui";
import { useCallback, useEffect, useState } from "react";
import { ErrorPage } from "../components/ErrorPage";
import { Page } from "../components/Page";
import { MileageInput } from "../components/MileageInput";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { colors } from "@qivia/ui/src/styles/figmaColors";

export const FuelDataForm = () => {
  const { t } = useTranslation();
  const [queryParameters] = useSearchParams();
  const transactionId = queryParameters.get("id");

  const dispatch = useAppDispatch();
  const updateTransactionDataStatus = useAppSelector(
    selectUpdateTransactionDataStatus,
  );
  const transactionHistoryStatus = useAppSelector(
    selectHistoryTransactionDataStatus,
  );
  const transactionData = useAppSelector(selectTransactionData);
  const [mileage, setMileage] = useState<number | "">(
    transactionData.mileage ?? "",
  );
  const [isExpenseCompleted, setIsExpenseCompleted] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);

  useEffect(() => {
    dispatch(transactionDataFormSlice.actions.resetUpdateStatus());
  }, [dispatch, t, updateTransactionDataStatus]);

  const clickToSubmitMileage = useCallback(() => {
    setDisplayError(!mileage);
    mileage && setIsExpenseCompleted(true);
  }, [mileage]);

  if (!transactionId) {
    return <ErrorPage />;
  }

  if (isExpenseCompleted) {
    return (
      <DriverSuccessPage
        title={t("success.expense.title")}
        subTitle={t("success.expense.subTitle")}
      />
    );
  }
  if (
    updateTransactionDataStatus === "processing" &&
    transactionHistoryStatus === "processing"
  ) {
    return <DriverLoadingPage />;
  }

  return (
    <Page
      helpButton
      bottomButton={
        <Button
          title={t("fuelDataForm.button.next")}
          onClick={clickToSubmitMileage}
          height={3.5}
          disabled={!mileage}
        />
      }
      title={t(`fuelDataForm.mileage.title`)}
    >
      <StyledContainer>
        <TextCapitalized>{t(`fuelDataForm.mileage.subTitle`)}</TextCapitalized>
        <Spacer y={1.5} />
        <MileageInput
          mileage={mileage}
          setMileage={setMileage}
          updateTransactionDataAsync={() =>
            void dispatch(
              updateTransactionDataAsync({
                transactionId,
                mileage: mileage !== "" ? mileage : null,
                quantity: transactionData.quantity,
              }),
            )
          }
          onKeyDownAction={clickToSubmitMileage}
          error={
            displayError && !mileage
              ? t("mileage.error.input") ?? ""
              : undefined
          }
          tooltipColor={colors["colors/text/lightGrey"]}
        />
      </StyledContainer>
    </Page>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${typographies["Paragraph/P1"]};
  colors: ${colors["colors/text/black"]};
`;
