import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstance } from "../../utils";
import { QrCodeResponseType, QrCodeType } from "./vehicleLoginApi";

export interface vehicleLoginState {
  qrCodeStatus: QueryStatus;
  cardHolderIdentification: string | null;
  qrCodeId: string;
  qrCodeStored: boolean;
}

const initialState: vehicleLoginState = {
  qrCodeStatus: "idle",
  cardHolderIdentification: null,
  qrCodeId: "",
  qrCodeStored: false,
};

export const qrCodeAsync = createAsyncThunk(
  "qrCode/call/",
  async (payload: QrCodeType) => {
    const axios = axiosInstance();
    const response = await axios.get<QrCodeResponseType>(
      `/driver_access/qr_code/${payload.qrCodeId}/card_holder_identification`,
    );
    return response.data;
  },
);

export const vehicleLoginSlice = createSlice({
  name: "vehicle_login",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setQrCodeId(state, action: PayloadAction<QrCodeType>) {
      state.qrCodeId = action.payload.qrCodeId;
      localStorage.setItem("qrCode", action.payload.qrCodeId);
    },
    setRegistrationNumber(
      state,
      action: PayloadAction<{ cardHolderIdentification: string }>,
    ) {
      state.cardHolderIdentification = action.payload.cardHolderIdentification;
    },
    getQrCodeId(state) {
      const qrCodeId = localStorage.getItem("qrCode");
      if (qrCodeId) {
        state.qrCodeId = qrCodeId;
      }
      state.qrCodeStored = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(qrCodeAsync.pending, (state) => {
        state.qrCodeStatus = "processing";
      })
      .addCase(qrCodeAsync.fulfilled, (state, action) => {
        state.cardHolderIdentification =
          action.payload.cardHolderIdentification;
        state.qrCodeStatus = "success";
      })
      .addCase(qrCodeAsync.rejected, (state) => {
        state.qrCodeStatus = "failed";
      });
  },
});

export const selectQrCodeStatus = (state: RootState) =>
  state.vehicleLogin.qrCodeStatus;
export const selectQrCodeStored = (state: RootState) =>
  state.vehicleLogin.qrCodeStored;
export const selectQrCodeId = (state: RootState) => state.vehicleLogin.qrCodeId;
export const selectCardHolderIdentification = (state: RootState) =>
  state.vehicleLogin.cardHolderIdentification;

export default vehicleLoginSlice.reducer;
