import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstancePhoneValidator } from "../../utils";
import {
  RequestValidationCodeType,
  VerifyValidationCodeResponseType,
  VerifyValidationCodeType,
} from "./driverPhoneAuthApi";
import { driverLoginSlice } from "./driverLoginSlice";
import { addPhoneAreaCode } from "@qivia/ui";

export interface driverPhoneAuthState {
  requestValidationCodeStatus: QueryStatus;
  verifyValidationCodeStatus: QueryStatus;
}

const initialState: driverPhoneAuthState = {
  requestValidationCodeStatus: "idle",
  verifyValidationCodeStatus: "idle",
};

export const requestValidationCodeAsync = createAsyncThunk(
  "driverPhoneAuthRequestValidationCode/call/",
  async (payload: RequestValidationCodeType) => {
    const axios = axiosInstancePhoneValidator();
    await axios.post(`/request_validation_code`, payload);
  },
);

export const verifyValidationCodeAsync = createAsyncThunk(
  "driverPhoneAuthVerifyValidationCode/call/",
  async (payload: VerifyValidationCodeType) => {
    const axios = axiosInstancePhoneValidator();
    const response = await axios.post<VerifyValidationCodeResponseType>(
      `/verify_validation_code`,
      payload,
    );
    return response.data;
  },
);

export const driverPhoneAuthSlice = createSlice({
  name: "driver_PhoneAuth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestValidationCodeAsync.pending, (state) => {
        state.requestValidationCodeStatus = "processing";
      })
      .addCase(requestValidationCodeAsync.fulfilled, (state, action) => {
        driverLoginSlice.actions.storeDriverPhone({
          phoneInput: addPhoneAreaCode(action.meta.arg.phone),
        }),
          driverLoginSlice.actions.setIsPhoneTokenDecoded({
            isPhoneTokenDecoded: false,
          }),
          (state.requestValidationCodeStatus = "success");
      })
      .addCase(requestValidationCodeAsync.rejected, (state) => {
        state.requestValidationCodeStatus = "failed";
      })
      .addCase(verifyValidationCodeAsync.pending, (state) => {
        state.verifyValidationCodeStatus = "processing";
      })
      .addCase(verifyValidationCodeAsync.fulfilled, (state, action) => {
        localStorage.setItem("phoneToken", action.payload.phoneToken);
        driverLoginSlice.actions.setIsPhoneTokenDecoded({
          isPhoneTokenDecoded: true,
        }),
          (state.verifyValidationCodeStatus = "success");
      })
      .addCase(verifyValidationCodeAsync.rejected, (state) => {
        state.verifyValidationCodeStatus = "failed";
        localStorage.removeItem("phoneToken");
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectRequestValidationCodeStatus = (state: RootState) =>
  state.driverPhoneAuth.requestValidationCodeStatus;

export const selectVerifyValidationCodeStatus = (state: RootState) =>
  state.driverPhoneAuth.verifyValidationCodeStatus;

export default driverPhoneAuthSlice.reducer;
