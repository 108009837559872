import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstanceWithPhoneToken } from "../../utils";
import {
  GetDriverKnownPayloadType,
  GetIsDriverKnownResponseType,
} from "./driverKnownApi";

export interface driverKnownState {
  getIsDriverKnownStatus: QueryStatus;
  isDriverKnown: boolean;
}

const initialState: driverKnownState = {
  getIsDriverKnownStatus: "idle",
  isDriverKnown: false,
};

export const getDriverAsync = createAsyncThunk(
  "getDriver/call/",
  async (payload: GetDriverKnownPayloadType) => {
    const phoneToken = localStorage.getItem("phoneToken");
    if (!phoneToken) {
      throw Error();
    }
    const axios = axiosInstanceWithPhoneToken(phoneToken);
    const response = await axios.get<GetIsDriverKnownResponseType>(
      `driver_access/qr_code/${payload.uuid}/is_driver_already_known`,
    );
    return response.data;
  },
);

export const driverKnown = createSlice({
  name: "driver_known",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDriverAsync.pending, (state) => {
        state.getIsDriverKnownStatus = "processing";
      })
      .addCase(getDriverAsync.fulfilled, (state, action) => {
        state.isDriverKnown = action.payload.isDriverKnown;
        state.getIsDriverKnownStatus = "success";
      })
      .addCase(getDriverAsync.rejected, (state) => {
        state.getIsDriverKnownStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectGetIsDriverKnownStatus = (state: RootState) =>
  state.driverKnown.getIsDriverKnownStatus;

export const selectIsDriverKnown = (state: RootState) =>
  state.driverKnown.isDriverKnown;

export default driverKnown.reducer;
