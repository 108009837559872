import { DriverLogoPage } from "./DriverLogoPage";

interface PageProps {
  title: string;
  subTitle: string;
}

export const DriverSuccessPage = (props: PageProps) => (
  <DriverLogoPage {...props} type={"SUCCESS"} />
);
