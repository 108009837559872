import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstance } from "../../utils";
import {
  CertificatOfHonourResponseType,
  CreateCertificatOfHonourType,
  UploadCertificatOfHonourType,
} from "./noSupportingDocumentAPI";

export interface NoSupportingDocumentState {
  createCertificateOfHonourStatus: QueryStatus;
  uploadCertificateOfHonourStatus: QueryStatus;
  signatureLink: string;
  documentId: string;
}

const initialState: NoSupportingDocumentState = {
  createCertificateOfHonourStatus: "idle",
  uploadCertificateOfHonourStatus: "idle",
  signatureLink: "",
  documentId: "",
};

export const createCertificateOfHonourAsync = createAsyncThunk(
  "createCertificateOfHonour/call",
  async (payload: CreateCertificatOfHonourType) => {
    const axios = axiosInstance();
    const response = await axios.post<CertificatOfHonourResponseType>(
      `/driver_access/create_certificate_of_honour`,
      payload,
    );
    return response.data;
  },
);

export const uploadCertificateOfHonourToS3Async = createAsyncThunk(
  "uploadCertificateOfHonourToS3/call",
  async (payload: UploadCertificatOfHonourType) => {
    const axios = axiosInstance();
    await axios.post(
      `/driver_access/supporting_documents/${payload.uuid}/upload_certificate_of_honour`,
      payload,
    );
  },
);

export const noSupportingDocumentSlice = createSlice({
  name: "no_supporting_document",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetUploadStatus(state) {
      state.uploadCertificateOfHonourStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCertificateOfHonourAsync.pending, (state) => {
        state.createCertificateOfHonourStatus = "processing";
      })
      .addCase(createCertificateOfHonourAsync.fulfilled, (state, action) => {
        state.createCertificateOfHonourStatus = "success";
        state.signatureLink = action.payload.signatureLink;
        state.documentId = action.payload.documentId;
      })
      .addCase(createCertificateOfHonourAsync.rejected, (state) => {
        state.createCertificateOfHonourStatus = "failed";
      })
      .addCase(uploadCertificateOfHonourToS3Async.pending, (state) => {
        state.uploadCertificateOfHonourStatus = "processing";
      })
      .addCase(uploadCertificateOfHonourToS3Async.fulfilled, (state) => {
        state.uploadCertificateOfHonourStatus = "success";
      })
      .addCase(uploadCertificateOfHonourToS3Async.rejected, (state) => {
        state.uploadCertificateOfHonourStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectCreateCertificateOfHonourStatus = (state: RootState) =>
  state.noSupportingDocument.createCertificateOfHonourStatus;

export const selectSignatureLink = (state: RootState) =>
  state.noSupportingDocument.signatureLink;

export const selectUploadCertificateOfHonourStatus = (state: RootState) =>
  state.noSupportingDocument.uploadCertificateOfHonourStatus;

export const selectDocumentId = (state: RootState) =>
  state.noSupportingDocument.documentId;

export default noSupportingDocumentSlice.reducer;
