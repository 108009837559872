import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstance } from "../../utils";
import { LinkQrCodeType } from "./linkQrCodeApi";

export interface linkQrCodeState {
  linkQrCodeStatus: QueryStatus;
  linkQrCodeError: string;
}

const initialState: linkQrCodeState = {
  linkQrCodeStatus: "idle",
  linkQrCodeError: "",
};

export const linkQrCodeAsync = createAsyncThunk(
  "linkQrCode/call/",
  async (payload: LinkQrCodeType) => {
    const axios = axiosInstance();
    const response = await axios.post<{ error: string }>(
      `/driver_access/qr_code/${payload.qrCodeId}/link_to_vehicle`,
      { cardHolderIdentification: payload.cardHolderIdentification },
    );
    return {
      error: response.data.error,
    };
  },
);

export const linkQrCodeSlice = createSlice({
  name: "link_qr_code",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(linkQrCodeAsync.pending, (state) => {
        state.linkQrCodeStatus = "processing";
      })
      .addCase(linkQrCodeAsync.fulfilled, (state, action) => {
        state.linkQrCodeError = action.payload.error;
        state.linkQrCodeStatus = "success";
      })
      .addCase(linkQrCodeAsync.rejected, (state) => {
        state.linkQrCodeStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectLinkQrCodeStatus = (state: RootState) =>
  state.linkQrCode.linkQrCodeStatus;

export const selectLinkQrCodeError = (state: RootState) =>
  state.linkQrCode.linkQrCodeError;

export default linkQrCodeSlice.reducer;
