import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstance } from "../../utils";
import {
  GetSupportingDocumentType,
  PublicUploadSupportingDocumentType,
  SupportingDocumentApi,
} from "./uploadSupportingDocumentAPI";
import axios from "axios";

export interface uploadSupportingDocumentState {
  supportingDocumentDataStatus: QueryStatus;
  supportingDocumentData: PublicUploadSupportingDocumentType | null;
  updateSupportingDocumentStatusState: QueryStatus;
  uploadSupportingDocumentToS3StatusState: QueryStatus;
  getSupportingDocumentStatus: QueryStatus;
  presignedUrl: string;
}

const initialState: uploadSupportingDocumentState = {
  supportingDocumentDataStatus: "idle",
  supportingDocumentData: null,
  updateSupportingDocumentStatusState: "idle",
  uploadSupportingDocumentToS3StatusState: "idle",
  getSupportingDocumentStatus: "idle",
  presignedUrl: "",
};

export const supportingDocumentDataAsync = createAsyncThunk(
  "supportingDocument/call",
  async (payload: SupportingDocumentApi) => {
    const axios = axiosInstance();
    const response = await axios.post<PublicUploadSupportingDocumentType>(
      `/driver_access/supporting_documents`,
      payload,
    );
    return response.data;
  },
);

export const uploadSupportingDocumentToS3Async = createAsyncThunk(
  "uploadSupportingDocument/uploadToS3",
  async (
    payload: {
      uuid: string;
      presignedUrl: string;
      fileContent: string | ArrayBuffer | null;
    },
    thunkAPI,
  ) => {
    const body =
      payload.fileContent && typeof payload.fileContent !== "string"
        ? new Uint8Array(payload.fileContent)
        : payload.fileContent
          ? payload.fileContent
          : "";
    await axios.put(payload.presignedUrl, body);
    void thunkAPI.dispatch(updateSupportingDocumentStatus(payload.uuid));
  },
);

export const updateSupportingDocumentStatus = createAsyncThunk(
  "updateSupportingDocument/call",
  async (uuid: string) => {
    const axios = axiosInstance();
    await axios.post(
      `/driver_access/supporting_documents/${uuid}/validate_status`,
    );
  },
);

export const getSupportingDocument = createAsyncThunk(
  "getSupportingDocument/call",
  async (uuid: string) => {
    const axios = axiosInstance();
    const response = await axios.post<{ presignedUrl: string }>(
      `/driver_access/supporting_documents/${uuid}/get_presigned_url`,
    );
    return response.data;
  },
);

export const supportingDocumentDownloadAsync = createAsyncThunk(
  "supportingDocumentDownload/call",
  async (supportingDocument: GetSupportingDocumentType, thunkAPI) => {
    if (supportingDocument.presignedUrl && supportingDocument.fileName) {
      const response = await axios({
        url: supportingDocument.presignedUrl,
        method: "GET",
        responseType: "blob",
      });
      const a = document.createElement("a");
      a.download = supportingDocument.fileName || "yourSupportingDocument.png";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
    void thunkAPI.dispatch(
      uploadSupportingDocumentSlice.actions.resetPresignedUrl(),
    );
  },
);

export const uploadSupportingDocumentSlice = createSlice({
  name: "upload_supporting_document",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetUploadStatus(state) {
      state.uploadSupportingDocumentToS3StatusState = "idle";
    },
    resetPresignedUrl(state) {
      state.presignedUrl = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(supportingDocumentDataAsync.pending, (state) => {
        state.supportingDocumentDataStatus = "processing";
      })
      .addCase(supportingDocumentDataAsync.fulfilled, (state, action) => {
        state.supportingDocumentData = action.payload;
        state.supportingDocumentDataStatus = "success";
      })
      .addCase(supportingDocumentDataAsync.rejected, (state) => {
        state.supportingDocumentDataStatus = "failed";
      })
      .addCase(updateSupportingDocumentStatus.pending, (state) => {
        state.updateSupportingDocumentStatusState = "processing";
      })
      .addCase(updateSupportingDocumentStatus.fulfilled, (state) => {
        state.updateSupportingDocumentStatusState = "success";
      })
      .addCase(updateSupportingDocumentStatus.rejected, (state) => {
        state.updateSupportingDocumentStatusState = "failed";
      })
      .addCase(uploadSupportingDocumentToS3Async.pending, (state) => {
        state.uploadSupportingDocumentToS3StatusState = "processing";
      })
      .addCase(uploadSupportingDocumentToS3Async.fulfilled, (state) => {
        state.uploadSupportingDocumentToS3StatusState = "success";
      })
      .addCase(uploadSupportingDocumentToS3Async.rejected, (state) => {
        state.uploadSupportingDocumentToS3StatusState = "failed";
      })
      .addCase(getSupportingDocument.pending, (state) => {
        state.getSupportingDocumentStatus = "processing";
      })
      .addCase(getSupportingDocument.fulfilled, (state, action) => {
        state.presignedUrl = action.payload.presignedUrl;
        state.getSupportingDocumentStatus = "success";
      })
      .addCase(getSupportingDocument.rejected, (state) => {
        state.getSupportingDocumentStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectUploadSupportingDocumentDataStatus = (state: RootState) =>
  state.uploadSupportingDocument.supportingDocumentDataStatus;

export const selectUploadSupportingDocumentData = (state: RootState) =>
  state.uploadSupportingDocument.supportingDocumentData;

export const selectUpdateSupportingDocumentState = (state: RootState) =>
  state.uploadSupportingDocument.updateSupportingDocumentStatusState;

export const selectUploadSupportingDocumentToS3Status = (state: RootState) =>
  state.uploadSupportingDocument.uploadSupportingDocumentToS3StatusState;

export const selectSupportingDocumentPresignedUrl = (state: RootState) =>
  state.uploadSupportingDocument.presignedUrl;

export default uploadSupportingDocumentSlice.reducer;
