import styled from "styled-components";
import {
  colors,
  Spacer,
  Assets,
  TextCapitalized,
  dateFormatterDayMonthYear,
  dateFormatterHourMinute,
} from "@qivia/ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PublicTransactionsListDisplayed } from "./transactionsListAPI";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  selectTransactionsListData,
  selectTransactionsListDataStatus,
  transactionsListAsync,
} from "./transactionsListSlice";
import { useCallback, useEffect } from "react";
import { ErrorPage } from "../components/ErrorPage";
import { isDateOver24h } from "../../utils";

export const TransactionsList = () => {
  const { t } = useTranslation();

  const [queryParameters] = useSearchParams();
  const transactionId = queryParameters.get("id");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const transactionsList: PublicTransactionsListDisplayed[] | null =
    useAppSelector(selectTransactionsListData);
  const transactionsListStatus = useAppSelector(
    selectTransactionsListDataStatus,
  );

  useEffect(() => {
    if (transactionId) {
      void dispatch(transactionsListAsync(transactionId));
    }
  }, [dispatch, transactionId]);

  const hasMissingMileage = useCallback(
    (transaction: PublicTransactionsListDisplayed) => {
      return (
        transaction.isFuelExpense &&
        !transaction.hasMileage &&
        !isDateOver24h(transaction.date)
      );
    },
    [],
  );

  const hasMissingSupportingDocument = useCallback(
    (transaction: PublicTransactionsListDisplayed) => {
      return (
        !transaction.hasSupportingDocument &&
        transaction.areSupportingDocumentsMandatory
      );
    },
    [],
  );

  const hasMissingData = useCallback(
    (transaction: PublicTransactionsListDisplayed) => {
      return (
        hasMissingSupportingDocument(transaction) ||
        hasMissingMileage(transaction)
      );
    },
    [hasMissingMileage, hasMissingSupportingDocument],
  );

  const displayMissingData = useCallback(
    (isMissing: boolean, dataName: string) => {
      if (!isMissing) return;
      return (
        <>
          <Spacer y={0.5} />
          <StyledErrorMessage>
            <Spacer x={0.5} />
            <TextCapitalized>
              {t(`transactionsList.missing.${dataName}`)}
            </TextCapitalized>
          </StyledErrorMessage>
        </>
      );
    },
    [t],
  );

  const displayAllMissingData = useCallback(
    (transaction: PublicTransactionsListDisplayed) => {
      return [
        {
          isDataEmpty: hasMissingSupportingDocument(transaction),
          name: "supportingDocument",
        },
        {
          isDataEmpty: hasMissingMileage(transaction),
          name: "mileage",
        },
      ].map((data) => {
        return displayMissingData(data.isDataEmpty, data.name);
      });
    },
    [displayMissingData, hasMissingMileage, hasMissingSupportingDocument],
  );

  if (transactionId === null || transactionsListStatus === "failed") {
    return <ErrorPage />;
  }
  return (
    <StyledContainer>
      <Spacer y={5} />
      <StyledHeader>
        <Spacer x={2} />
        <TextCapitalized>{t("transactionsList.header")}</TextCapitalized>
        <Spacer x={0.5} />
      </StyledHeader>
      <Spacer y={2} />
      <StyledBottom>
        <Spacer y={2} />
        <StyledFlex>
          <Spacer x={2} />
          <StyledMediumTitle>
            <TextCapitalized>{t("transactionsList.history")}</TextCapitalized>
          </StyledMediumTitle>
        </StyledFlex>
        <Spacer y={2} />
        {transactionsList?.map((transaction, index) => {
          return (
            <div key={index + "list"}>
              <StyledFlex
                onClick={() => navigate(`/transaction?id=${transaction.uuid}`)}
              >
                <StyledError>
                  {hasMissingData(transaction) && <StyledCircleError />}
                </StyledError>
                <StyledBodyContainer>
                  <StyledGasStation>
                    <Assets.GasStation />
                  </StyledGasStation>
                  <Spacer x={1} />
                  <StyledBody>
                    <StyledTitle>{transaction.merchantName}</StyledTitle>
                    <Spacer y={0.25} />
                    <StyledDate>
                      {transaction.date
                        ? dateFormatterDayMonthYear(new Date(transaction.date))
                        : ""}
                      <Spacer x={0.25} />
                      à
                      <Spacer x={0.25} />
                      {transaction.date
                        ? dateFormatterHourMinute(new Date(transaction.date))
                        : ""}
                    </StyledDate>
                    {displayAllMissingData(transaction)}
                  </StyledBody>
                  <Spacer x={1} />
                  <StyledAmount>
                    <StyledTitle>
                      {transaction.status === "PENDING_CAPTURE"
                        ? "-"
                        : transaction.amount / 100}
                    </StyledTitle>
                    <Spacer x={0.5} />
                    {transaction.currency}
                    <Spacer x={2} />
                  </StyledAmount>
                </StyledBodyContainer>
              </StyledFlex>
              <Spacer y={2} />
            </div>
          );
        })}
      </StyledBottom>
    </StyledContainer>
  );
};

const StyledErrorMessage = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 0.85rem;
  border-left: 0.0625rem solid ${colors.red};
  color: ${colors.red};
`;

const StyledError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
`;

const StyledCircleError = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${colors.red};
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledBodyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 1rem;
`;

const StyledMediumTitle = styled.div`
  font-weight: 500;
  font-size: 1rem;
`;

const StyledDate = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 0.85rem;
  color: ${colors.contentSecondary};
`;

const StyledBody = styled.div`
  width: 100%;
`;

const StyledAmount = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 0.85rem;
`;

const StyledGasStation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.75rem;
  height: 2.5rem;
  background-color: ${colors.background};
  border-radius: 1.5rem;
`;

const StyledBottom = styled.div`
  background-color: ${colors.white};
`;

const StyledHeader = styled.div`
  display: flex;
  height: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${colors.background};
`;
