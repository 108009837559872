import { HTMLAttributes } from "react";
import { RowElement } from "./RowElement";
import { Switch } from "../Switch";

export interface RowToggleProps extends HTMLAttributes<HTMLButtonElement> {
  text: string;
  icon: JSX.Element;
  onClick: () => void;
  size: sizeRowToggle;
  check: boolean;
  tooltip?: JSX.Element;
  disabled?: boolean;
}

type sizeRowToggle = "S" | "L";

const rowToogleOptions = {
  S: {
    colorToogle: "GREEN" as const,
    sizeToogle: "S" as const,
  },
  L: {
    colorToogle: "RED" as const,
    sizeToogle: "M" as const,
  },
};

export const RowToggle = (props: RowToggleProps) => {
  const options = rowToogleOptions[props.size];

  return (
    <RowElement
      rightElement={
        <Switch
          onClick={props.onClick}
          checked={props.check}
          color={options.colorToogle}
          size={options.sizeToogle}
          disabled={props.disabled}
        />
      }
      leftIcon={props.icon}
      {...props}
    />
  );
};
