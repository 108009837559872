import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstance } from "../../utils";
import {
  PublicTransactionDisplayed,
  PublicTransactionType,
} from "./transactionPageAPI";

export interface transactionPageState {
  transactionDataStatus: QueryStatus;
  transactionData: PublicTransactionDisplayed | null;
}

const initialState: transactionPageState = {
  transactionDataStatus: "idle",
  transactionData: null,
};

export const transactionDataAsync = createAsyncThunk(
  "transactionPage/call",
  async (transactionId: string) => {
    const axios = axiosInstance();
    const response = await axios.get<PublicTransactionType>(
      `driver_access/transaction/${transactionId}`,
    );
    return {
      amount: response.data.amount.value,
      currency: response.data.amount.currency,
      date: response.data.executionDate,
      merchantName: response.data.merchantName,
      merchantCity: response.data.merchantCity,
      merchantCountry: response.data.merchantCountry,
      status: response.data.status,
    };
  },
);

export const transactionPageSlice = createSlice({
  name: "upload_supporting_document",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(transactionDataAsync.pending, (state) => {
        state.transactionDataStatus = "processing";
      })
      .addCase(transactionDataAsync.fulfilled, (state, action) => {
        state.transactionData = action.payload;
        state.transactionDataStatus = "success";
      })
      .addCase(transactionDataAsync.rejected, (state) => {
        state.transactionDataStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTransactionDataStatus = (state: RootState) =>
  state.transactionPage.transactionDataStatus;

export const selectTransactionData = (state: RootState) =>
  state.transactionPage.transactionData;

export const getCurrentTransactionFormatted = createSelector(
  (state: Required<Pick<RootState, "transactionPage">>) =>
    state.transactionPage.transactionData,
  (
    transaction: PublicTransactionDisplayed | null,
  ): (PublicTransactionDisplayed & { time: string }) | null =>
    transaction === null
      ? null
      : {
          amount: (transaction?.amount ?? 0) / 100,
          currency: transaction?.currency ?? "EUR",
          merchantName: transaction?.merchantName ?? "",
          merchantCity: transaction?.merchantCity ?? "",
          merchantCountry: transaction?.merchantCountry ?? "",
          status: transaction?.status ?? "",
          date: !transaction?.date
            ? ""
            : new Date(transaction.date).toLocaleDateString("fr-fr", {
                year: "numeric",
                month: "long",
                day: "numeric",
              }),
          time: !transaction?.date
            ? ""
            : new Date(transaction.date).toLocaleString("fr-fr", {
                hour: "numeric",
                minute: "numeric",
              }),
        },
);

export default transactionPageSlice.reducer;
