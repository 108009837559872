import { Spacer, Assets, colors, TextCapitalized } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export interface PageProps {
  title: string | JSX.Element | JSX.Element[] | null;
  children?: string | JSX.Element | JSX.Element[];
  bottomButton?: JSX.Element;
  helpButton?: boolean;
  returnButton?: boolean;
  returnButtonTitle?: string;
  returnButtonAction?: () => void;
  withoutHeaderSpacer?: boolean;
}

export const Page = (props: PageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledPageContainer>
      <StyledContent>
        <Spacer y={2.5} />
        <StyledHeaderContainer>
          {props.returnButton ? (
            <StyledReturnButton onClick={props.returnButtonAction}>
              <Spacer x={2} />
              <Assets.NavigateArrowLeft />
              <Spacer x={1} />
              <TextCapitalized>
                {props.returnButtonTitle
                  ? props.returnButtonTitle
                  : t("page.button.return")}
              </TextCapitalized>
            </StyledReturnButton>
          ) : (
            <Spacer y={1} />
          )}
          {props.helpButton ? (
            <StyledFlex>
              <StyledHelpButton onClick={() => navigate("/help")}>
                <TextCapitalized>{t("page.button.help")}</TextCapitalized>
              </StyledHelpButton>
              <Spacer x={2} />
            </StyledFlex>
          ) : (
            <Spacer y={1} />
          )}
        </StyledHeaderContainer>
        {props.returnButton && !props.withoutHeaderSpacer ? (
          <Spacer y={5} />
        ) : (
          <Spacer y={1.5} />
        )}
        <StyledTitle>
          <Spacer x={2} />
          <TextCapitalized>{props.title}</TextCapitalized>
          <Spacer x={2} />
        </StyledTitle>
        <Spacer y={1.5} />
        <StyledFlex>
          <Spacer x={2} />
          {props.children}
          <Spacer x={2} />
        </StyledFlex>
        <Spacer y={5} />
      </StyledContent>
      {props.bottomButton && (
        <StyledBottomButton>
          <StyledAbsolute>
            <StyledFlex>
              <Spacer x={2} />
              {props.bottomButton}
              <Spacer x={2} />
            </StyledFlex>
            <Spacer y={1.5} />
          </StyledAbsolute>
        </StyledBottomButton>
      )}
    </StyledPageContainer>
  );
};

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100dvh;
  width: 100%;
  font-family: "Inter";
  font-size: 1rem;
  font-style: normal;
  background-color: ${colors.background};
  color: ${colors.contentSecondary};
`;

const StyledContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const StyledTitle = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 2rem;
  color: ${colors.black};
`;

const StyledFlex = styled.div`
  display: flex;
  height: 100%;
`;

const StyledBottomButton = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: transparent;
`;

const StyledAbsolute = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: transparent;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  min-width: 100%;
  justify-content: space-between;
`;

const StyledReturnButton = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
  color: ${colors.black};
  cursor: pointer;
`;

const StyledHelpButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.25rem;
  height: 1.75rem;
  border-radius: 1.75rem;
  font-weight: 500;
  font-size: 0.75rem;
  color: ${colors.black};
  background-color: ${colors.white};
  cursor: pointer;
`;
