import styled from "styled-components";
import { Spacer, colors, Assets, capitalize, UndoOutlined } from "@qivia/ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getCurrentTransactionFormatted,
  selectTransactionDataStatus,
  transactionDataAsync,
} from "./transactionPageSlice";
import { ReactNode, useEffect } from "react";
import { ErrorPage } from "../components/ErrorPage";
import { DriverCanceledPage } from "@qivia/ui";
import { useTranslation } from "react-i18next";

interface TransactionPageProps {
  toaster?: JSX.Element;
  returnButtonText?: string;
  children?: ReactNode;
}

export const TransactionPage = (props: TransactionPageProps) => {
  const [queryParameters] = useSearchParams();
  const transactionId = queryParameters.get("id");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentTransaction = useAppSelector(getCurrentTransactionFormatted);
  const transactionDataStatus = useAppSelector(selectTransactionDataStatus);

  useEffect(() => {
    if (transactionId) {
      void dispatch(transactionDataAsync(transactionId));
    }
  }, [dispatch, transactionId]);

  if (
    !transactionId ||
    transactionDataStatus === "failed" ||
    (transactionDataStatus === "success" && currentTransaction === null)
  ) {
    return <ErrorPage />;
  }

  const cta = {
    title: t("transactions.canceled.cta"),
    action: () => {
      navigate(`/transactions_list?id=${transactionId}`);
    },
    leftIcon: <UndoOutlined />,
  };

  if (
    transactionDataStatus === "success" &&
    currentTransaction &&
    currentTransaction.status === "CANCELED"
  ) {
    return (
      <DriverCanceledPage
        title={t("transactions.canceled.title")}
        subTitle={t("transactions.canceled.subtitle")}
        cta={cta}
      />
    );
  }

  return (
    <StyledContainer>
      {props.toaster}
      <Spacer y={3.25} />
      {props.returnButtonText && (
        <StyledReturnButtonContainer
          onClick={() => navigate(`/transactions_list?id=${transactionId}`)}
        >
          <Spacer x={2} />
          <Assets.NavigateArrowLeft />
          <Spacer x={1} />
          <StyledReturnButtonBody>
            {props.returnButtonText}
          </StyledReturnButtonBody>
        </StyledReturnButtonContainer>
      )}
      <Spacer y={2} />
      <StyledContent>
        <StyledAmount>
          <Spacer x={2} />
          <StyledTitle>
            {currentTransaction?.status === "PENDING_CAPTURE"
              ? "-"
              : currentTransaction?.amount}
          </StyledTitle>
          <Spacer x={0.5} />
          {currentTransaction?.currency}
        </StyledAmount>
        <Spacer y={0.75} />
        <StyledTitle>
          <Spacer x={2} />
          {currentTransaction?.merchantName}
        </StyledTitle>
        <Spacer y={1.5} />
        <StyledFlex>
          <Spacer x={2} />
          {currentTransaction?.date}
          <Spacer x={0.25} />
          à
          <Spacer x={0.25} />
          {currentTransaction?.time}
        </StyledFlex>
        <Spacer y={0.25} />
        <StyledFlex>
          <Spacer x={2} />
          {capitalize(currentTransaction?.merchantCity ?? "")},{" "}
          {currentTransaction?.merchantCountry === "FRA"
            ? "FRANCE"
            : currentTransaction?.merchantCountry}
          <Spacer x={2} />
        </StyledFlex>
      </StyledContent>
      <Spacer y={2} />
      <StyledBottom>
        <Spacer y={2} />
        <StyledFlex>
          <Spacer x={2} />
          {props.children}
          <Spacer x={2} />
        </StyledFlex>
        <Spacer y={2} />
      </StyledBottom>
    </StyledContainer>
  );
};

const StyledReturnButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledReturnButtonBody = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  &::first-letter {
    text-transform: uppercase;
  }
`;
const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  height: 100%;
  justify-content: flex-end;
`;

const StyledAmount = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: end !important;
  font-weight: 500;
  font-size: 2rem;
  line-height: 100%;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledBottom = styled.div`
  background-color: ${colors.white};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  width: 100%;
  background-color: ${colors.background};
`;
