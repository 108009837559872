import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstance } from "../../utils";
import {
  PublicTransactionType,
  PublicTransactionsListDisplayed,
  CategoryType,
} from "./transactionsListAPI";

export interface transactionsListState {
  transactionsListStatus: QueryStatus;
  transactionsList: PublicTransactionsListDisplayed[] | null;
}

const initialState: transactionsListState = {
  transactionsListStatus: "idle",
  transactionsList: null,
};

export const transactionsListAsync = createAsyncThunk(
  "transactionsList/call",
  async (transactionId: string) => {
    const axios = axiosInstance();
    const response = await axios.get<Array<PublicTransactionType>>(
      `driver_access/transaction/${transactionId}/transactions_list`,
    );
    return response.data
      .filter(
        (elem) =>
          elem.status !== "CANCELED" && elem.status !== "PENDING_ADJUSTEMENT",
      )
      .map(
        ({
          amount,
          executionDate,
          mileage,
          quantity,
          category,
          ...payload
        }) => ({
          ...payload,
          amount: amount.value,
          currency: amount.currency,
          date: executionDate,
          hasMileage: mileage !== 0,
          hasQuantity: quantity !== 0,
          isFuelExpense: (
            ["FUEL_AUTOMATON", "SERVICE_STATION", "ELECTRIC"] as CategoryType[]
          ).includes(category),
        }),
      )
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  },
);

export const transactionsListSlice = createSlice({
  name: "upload_supporting_document",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(transactionsListAsync.pending, (state) => {
        state.transactionsListStatus = "processing";
      })
      .addCase(transactionsListAsync.fulfilled, (state, action) => {
        state.transactionsList = action.payload;
        state.transactionsListStatus = "success";
      })
      .addCase(transactionsListAsync.rejected, (state) => {
        state.transactionsListStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTransactionsListDataStatus = (state: RootState) =>
  state.transactionsList.transactionsListStatus;

export const selectTransactionsListData = (state: RootState) =>
  state.transactionsList.transactionsList;

export default transactionsListSlice.reducer;
