import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getDriverAsync,
  selectGetIsDriverKnownStatus,
  selectIsDriverKnown,
} from "./driverKnownSlice";
import { selectQrCodeId } from "./vehicleLoginSlice";
import { ErrorPage } from "../components/ErrorPage";
import { LaunchPage } from "@qivia/ui";

export const DriverKnown = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDriverKnown = useAppSelector(selectIsDriverKnown);
  const isDriverKnownStatus = useAppSelector(selectGetIsDriverKnownStatus);
  const qrCodeId = useAppSelector(selectQrCodeId);

  useEffect(() => {
    if (!qrCodeId) return;
    void dispatch(getDriverAsync({ uuid: qrCodeId }));
  }, [dispatch, navigate, qrCodeId]);

  useEffect(() => {
    if (isDriverKnownStatus !== "success") return;
    const pageToRedirect = isDriverKnown ? "/menu" : "/driver_register";
    navigate(pageToRedirect);
  }, [isDriverKnownStatus, isDriverKnown, navigate, dispatch]);

  if (isDriverKnownStatus === "processing") {
    return <LaunchPage />;
  }

  if (isDriverKnownStatus === "failed" || qrCodeId === null)
    return <ErrorPage page="qrCode" />;
};
