import { DriverLogoPage } from "./DriverLogoPage";

interface PageProps {
  title: string;
  subTitle: string;
  cta?: {
    title: string;
    action: () => void;
    width?: number;
    leftIcon?: JSX.Element | null;
  };
}

export const DriverCanceledPage = (props: PageProps) => {
  return <DriverLogoPage {...props} type={"CANCELED"} />;
};
