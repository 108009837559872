import { FileInputButton, triggerToast } from "@qivia/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  selectUploadSupportingDocumentData,
  selectUploadSupportingDocumentDataStatus,
  selectUploadSupportingDocumentToS3Status,
  supportingDocumentDataAsync,
  uploadSupportingDocumentSlice,
  uploadSupportingDocumentToS3Async,
} from "./uploadSupportingDocumentSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { PublicUploadSupportingDocumentType } from "./uploadSupportingDocumentAPI";

export interface UploadSupportingDocumentButtonProps {
  transactionId: string;
  children?: string | JSX.Element | JSX.Element[];
}

export const UploadSupportingDocumentButton = (
  props: UploadSupportingDocumentButtonProps,
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [fileContent, setFileContent] = useState<string | ArrayBuffer | null>(
    null,
  );

  const loadUploadSupportingDocumentData: PublicUploadSupportingDocumentType | null =
    useAppSelector(selectUploadSupportingDocumentData);
  const loadPresignedUrlStatus = useAppSelector(
    selectUploadSupportingDocumentDataStatus,
  );
  const uploadSupportingDocumentToS3Status = useAppSelector(
    selectUploadSupportingDocumentToS3Status,
  );

  useEffect(() => {
    if (
      loadUploadSupportingDocumentData &&
      loadPresignedUrlStatus === "success" &&
      fileContent
    )
      void dispatch(
        uploadSupportingDocumentToS3Async({
          uuid: loadUploadSupportingDocumentData.uuid,
          presignedUrl: loadUploadSupportingDocumentData.presignedUrl,
          fileContent,
        }),
      );
  }, [
    fileContent,
    dispatch,
    loadUploadSupportingDocumentData,
    loadPresignedUrlStatus,
  ]);

  useEffect(() => {
    if (uploadSupportingDocumentToS3Status === "success")
      triggerToast(t("supportingDocument.upload.success") || "", "valid");
    if (uploadSupportingDocumentToS3Status === "failed") {
      triggerToast(t("supportingDocument.upload.error") || "", "error");
    }
    dispatch(uploadSupportingDocumentSlice.actions.resetUploadStatus());
  }, [uploadSupportingDocumentToS3Status, t, dispatch]);

  const readFile = (file: File | null) => {
    if (!file || !props.transactionId) return;

    const regex = /^.+\.(([pP][dD][fF])|([jJ][pP][eE]?[gG])|([pP][nN][gG]))$/;
    const reader = new FileReader();
    reader.onload = () => {
      if (file.name.match(regex)) {
        const content = reader.result;
        if (!content) return;
        void dispatch(
          supportingDocumentDataAsync({
            transactionId: props.transactionId,
            originalFileName: file.name,
          }),
        );
        setFileContent(content);
      } else {
        triggerToast(t("supportingDocument.incorrectType") || "", "error");
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <FileInputButton handleFile={(file) => readFile(file as File)}>
      {props.children}
    </FileInputButton>
  );
};
