import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  Spacer,
  Button,
  colors,
  TextCapitalized,
  triggerToast,
  ToastContainer,
} from "@qivia/ui";

export const NeedHelpPage = () => {
  const { t } = useTranslation();

  return (
    <StyledSupportCard>
      <ToastContainer />
      <Spacer y={5} />
      <StyledRow>
        <Spacer x={2} />
        <StyledColumn>
          <StyledSupportCardTitle>
            <TextCapitalized>{t("needHelp.support.title")}</TextCapitalized>
          </StyledSupportCardTitle>
          <Spacer y={1.5} />
          <StyledFlex>
            <TextCapitalized>{t("needHelp.support.body")}</TextCapitalized>
          </StyledFlex>
          <Spacer y={4} />
          <StyledFlex>
            <Button
              title={t("needHelp.support.phone")}
              height={3.5}
              fontWeight={600}
              fontSize={1.1}
              onClick={() => {
                void navigator.clipboard.writeText(
                  t("needHelp.support.phone.toPaste"),
                );
                triggerToast(
                  t("needHelp.support.phone.copy.success") || "",
                  "valid",
                );
              }}
              noColorHover
            />
          </StyledFlex>
        </StyledColumn>
        <Spacer x={2} />
      </StyledRow>
      <Spacer y={5} />
    </StyledSupportCard>
  );
};

const StyledSupportCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  font-family: "Inter";
  font-weight: 400;
  font-size: 1rem;
  font-style: normal;
  background-color: ${colors.white};
`;

const StyledSupportCardTitle = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 2.25rem;
`;

const StyledFlex = styled.div`
  display: flex;
  width: 100%;
`;

const StyledRow = styled.div`
  display: flex;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
