import { useTranslation } from "react-i18next";
import { Page } from "../components/Page";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  SelectInput,
  Spacer,
  hasBirthDateFormat,
} from "@qivia/ui";
import styled from "styled-components";
import { OptionType } from "./noSupportingDocumentForm";
import "i18next";
import "react-i18next";
import "../../i18n/i18n";
import { AddressAutofill } from "@mapbox/search-js-react";
import { QuantityInput } from "../components/QuantityInput";
interface AddressFormProps {
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  postalCode: string;
  country: string;
}

interface AddressProps {
  label: string;
  addressForm: AddressFormProps;
  onChange: (address: keyof AddressFormProps, value: string) => void;
  error?: boolean;
  onKeyDown?: (e: string) => void;
}

export const AddressForm = (props: AddressProps) => {
  const { t } = useTranslation();
  const accessToken = import.meta.env["VITE_MAPBOX_TOKEN"] as string;

  return (
    <form>
      <AddressAutofill accessToken={accessToken || ""}>
        <Input
          label={props.label}
          placeholder={t("address.placeholder.addressLine1") || ""}
          onChange={(e) =>
            props.onChange(
              "addressLine1" as keyof AddressFormProps,
              e.target.value,
            )
          }
          value={props.addressForm.addressLine1}
          error={
            !props.addressForm?.addressLine1 && props.error
              ? t("address.error.input") || ""
              : undefined
          }
          autoComplete="address-line1"
          noneBottomSpacer
          onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e.key)}
        />
      </AddressAutofill>
      <Input
        placeholder={t("address.placeholder.addressLine2") || ""}
        onChange={(e) => props.onChange("addressLine2", e.target.value)}
        value={
          props.addressForm.addressLine2 ? props.addressForm.addressLine2 : ""
        }
        autoComplete="address-line2"
        noneBottomSpacer
        onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e.key)}
      />
      <StyledCityAndPostalCode>
        <Input
          placeholder={t("address.placeholder.postalCode") || ""}
          onChange={(e) => props.onChange("postalCode", e.target.value)}
          value={props.addressForm.postalCode}
          error={
            !props.addressForm?.postalCode && props.error
              ? t("address.error.input") || ""
              : undefined
          }
          autoComplete="postal-code"
          noneBottomSpacer
          onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e.key)}
        />
        <Spacer x={0.5} />
        <Input
          placeholder={t("address.placeholder.city") || ""}
          onChange={(e) => props.onChange("city", e.target.value)}
          value={props.addressForm.city}
          error={
            !props.addressForm?.city && props.error
              ? t("address.error.input") || ""
              : undefined
          }
          autoComplete="address-level2"
          noneBottomSpacer
          onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e.key)}
        />
      </StyledCityAndPostalCode>
      <StyledCountry>
        <Input
          value={t("address.country.france") + " 🇫🇷"}
          //autoComplete="country"
          disabled={true}
        />
        <Spacer x={0.25} />
      </StyledCountry>
    </form>
  );
};

const StyledCityAndPostalCode = styled.div`
  display: flex;
`;
const StyledCountry = styled.div`
  display: flex;
  width: 50%;
`;

export const ExpenseTypePage = (props: {
  expenseType: OptionType | null;
  setExpenseType: (value: OptionType | null) => void;
  followingPage: (isFuelCategory: boolean) => void;
  transactionId: string;
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [isFuelExpense, setIsFuelExpense] = useState<boolean>(false);

  const updateIsFuelExpense = useCallback(
    (option: string | undefined) => {
      setIsFuelExpense(
        option
          ? ![
              t("select.wash"),
              t("select.parking"),
              t("select.toll"),
              t("select.electric"),
            ].includes(option)
          : false,
      );
    },
    [t],
  );

  const fuelOptions = [
    { value: t("select.fuel.SP95"), label: t("select.fuel.SP95") },
    { value: t("select.fuel.SP95_E10"), label: t("select.fuel.SP95_E10") },
    { value: t("select.fuel.SP98"), label: t("select.fuel.SP98") },
    { value: t("select.fuel.B7"), label: t("select.fuel.B7") },
    { value: t("select.fuel.B10"), label: t("select.fuel.B10") },
    { value: t("select.fuel.B30"), label: t("select.fuel.B30") },
    { value: t("select.fuel.GPL"), label: t("select.fuel.GPL") },
    { value: t("select.fuel.E85"), label: t("select.fuel.E85") },
    { value: t("select.fuel.ED95"), label: t("select.fuel.ED95") },
    { value: t("select.fuel.GNV"), label: t("select.fuel.GNV") },
    { value: t("select.fuel.GNC"), label: t("select.fuel.GNC") },
    { value: t("select.fuel.GNL"), label: t("select.fuel.GNL") },
    { value: t("select.fuel.AdBlue"), label: t("select.fuel.AdBlue") },
    { value: t("select.wash"), label: t("select.wash") },
    { value: t("select.parking"), label: t("select.parking") },
    { value: t("select.toll"), label: t("select.toll") },
    { value: t("select.electric"), label: t("select.electric") },
  ];

  return (
    <Page
      title={t(`noSupportingDocumentForm.fuel`)}
      bottomButton={
        <Button
          title={t("noSupportingDocumentForm.button.next")}
          onClick={() => {
            if (props.expenseType === null) {
              setDisplayError(true);
            } else {
              setDisplayError(false);
              props.followingPage(isFuelExpense);
            }
          }}
          height={3.5}
          disabled={props.expenseType === null}
        />
      }
      returnButton
      returnButtonAction={() =>
        navigate(`/no_supporting_document?id=${props.transactionId}`)
      }
      returnButtonTitle={t("noSupportingDocument.button.return") || ""}
    >
      <StyledContainer>
        <SelectInput
          label=""
          placeholder={t("noSupportingDocumentForm.fuel.placeholder") || ""}
          value={props.expenseType}
          options={fuelOptions}
          onChange={(selectedOption) => {
            props.setExpenseType(selectedOption);
            updateIsFuelExpense(selectedOption?.value);
          }}
          error={
            (displayError &&
              props.expenseType === null &&
              t("noSupportingDocumentForm.error.selectInput")) ||
            undefined
          }
        />
      </StyledContainer>
    </Page>
  );
};

export const DriverName = (props: {
  firstName: string;
  lastName: string;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  previousPage: () => void;
  followingPage: () => void;
}) => {
  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState<boolean>(false);

  return (
    <Page
      title={t(`noSupportingDocumentForm.name`)}
      bottomButton={
        <Button
          title={t("noSupportingDocumentForm.button.next")}
          onClick={() => {
            if (props.firstName === "" || props.lastName === "") {
              setDisplayError(true);
            } else {
              setDisplayError(false);
              props.followingPage();
            }
          }}
          height={3.5}
          disabled={props.firstName === "" || props.lastName === ""}
        />
      }
      returnButton
      returnButtonAction={() => props.previousPage()}
      returnButtonTitle={t("noSupportingDocument.button.return") || ""}
    >
      <StyledContainer>
        <Input
          label={t("noSupportingDocumentForm.name.label.firstName") || ""}
          placeholder={
            t("noSupportingDocumentForm.name.placeholder.firstName") || ""
          }
          defaultValue={props.firstName}
          onChange={(e) => props.setFirstName(e.target.value)}
          error={
            (displayError &&
              props.firstName === "" &&
              t("noSupportingDocumentForm.error.input")) ||
            undefined
          }
        />
        <Input
          label={t("noSupportingDocumentForm.name.label.lastName") || ""}
          placeholder={
            t("noSupportingDocumentForm.name.placeholder.lastName") || ""
          }
          defaultValue={props.lastName}
          onChange={(e) => props.setLastName(e.target.value)}
          error={
            (displayError &&
              props.lastName === "" &&
              t("noSupportingDocumentForm.error.input")) ||
            undefined
          }
        />
      </StyledContainer>
    </Page>
  );
};

export const BirthDatePage = (props: {
  birthDate: string;
  setBirthDate: (value: string) => void;
  previousPage: () => void;
  followingPage: () => void;
}) => {
  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState<boolean>(false);

  return (
    <Page
      title={t(`noSupportingDocumentForm.birthDate`)}
      bottomButton={
        <Button
          title={t("noSupportingDocumentForm.button.next")}
          onClick={() => {
            if (!hasBirthDateFormat(props.birthDate)) {
              setDisplayError(true);
            } else {
              setDisplayError(false);
              props.followingPage();
            }
          }}
          height={3.5}
          disabled={!hasBirthDateFormat(props.birthDate)}
        />
      }
      returnButton
      returnButtonAction={() => props.previousPage()}
      returnButtonTitle={t("noSupportingDocument.button.return") || ""}
    >
      <Input
        label={t("noSupportingDocumentForm.birthDate.label") || ""}
        placeholder={t("noSupportingDocumentForm.birthDate.placeholder") || ""}
        defaultValue={props.birthDate}
        onChange={(e) => props.setBirthDate(e.target.value)}
        error={
          (displayError &&
            !hasBirthDateFormat(props.birthDate) &&
            t("noSupportingDocumentForm.error.birthDateFormat")) ||
          undefined
        }
        isPlaceholderUppercase
      />
    </Page>
  );
};

export const JobPage = (props: {
  job: string;
  setJob: (value: string) => void;
  previousPage: () => void;
  followingPage: () => void;
}) => {
  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState<boolean>(false);

  return (
    <Page
      title={t(`noSupportingDocumentForm.job`)}
      bottomButton={
        <Button
          title={t("noSupportingDocumentForm.button.next")}
          onClick={() => {
            if (props.job === "") {
              setDisplayError(true);
            } else {
              setDisplayError(false);
              props.followingPage();
            }
          }}
          height={3.5}
          disabled={props.job === ""}
        />
      }
      returnButton
      returnButtonAction={() => props.previousPage()}
      returnButtonTitle={t("noSupportingDocument.button.return") || ""}
    >
      <Input
        label={t("noSupportingDocumentForm.job.label") || ""}
        placeholder={t("noSupportingDocumentForm.job.placeholder") || ""}
        defaultValue={props.job}
        onChange={(e) => props.setJob(e.target.value)}
        error={
          (displayError &&
            props.job === "" &&
            t("noSupportingDocumentForm.error.input")) ||
          undefined
        }
      />
    </Page>
  );
};

export const QuantityPage = (props: {
  quantity: number | "";
  setQuantity: (value: number | "") => void;
  previousPage: () => void;
  followingPage: () => void;
}) => {
  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState<boolean>(false);

  const onSubmit = useCallback(() => {
    if (props.quantity === "") {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      props.followingPage();
    }
  }, [props]);

  return (
    <Page
      title={t(`noSupportingDocumentForm.quantity`)}
      bottomButton={
        <Button
          title={t("noSupportingDocumentForm.button.next")}
          onClick={onSubmit}
          height={3.5}
          disabled={props.quantity === ""}
        />
      }
      returnButton
      returnButtonAction={() => props.previousPage()}
      returnButtonTitle={t("noSupportingDocument.button.return") || ""}
    >
      <QuantityInput
        error={
          (displayError &&
            props.quantity === "" &&
            t("noSupportingDocumentForm.error.input")) ||
          undefined
        }
        quantity={props.quantity}
        setQuantity={props.setQuantity}
        onKeyDownAction={onSubmit}
        tooltipColor="transparent"
      />
    </Page>
  );
};

export const AddressPage = (props: {
  address: string;
  setAddress: (value: string) => void;
  previousPage: () => void;
  followingPage: () => void;
}) => {
  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState<boolean>(false);

  const [addressState, setAddressState] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    postalCode: "",
    country: "",
  });

  const updateAddress = (key: keyof AddressFormProps, value: string) => {
    const newValueState = {
      ...addressState,
      [key]: value,
    };
    setAddressState(newValueState);
  };

  const hasError =
    addressState.addressLine1 === "" ||
    addressState.city === "" ||
    addressState.postalCode === "";
  const addressStringify =
    addressState.addressLine1 +
    (addressState.addressLine2 && " " + addressState.addressLine2) +
    ",  " +
    addressState.postalCode +
    " " +
    addressState.city +
    ", FRANCE";

  return (
    <Page
      title={t(`noSupportingDocumentForm.address`)}
      bottomButton={
        <Button
          title={t("noSupportingDocumentForm.button.next")}
          onClick={() => {
            if (hasError) {
              setDisplayError(true);
            } else {
              setDisplayError(false);
              props.setAddress(addressStringify);
              props.followingPage();
            }
          }}
          height={3.5}
          disabled={hasError}
        />
      }
      returnButton
      returnButtonAction={() => props.previousPage()}
      returnButtonTitle={t("noSupportingDocument.button.return") || ""}
    >
      <StyledContainer>
        <AddressForm
          onChange={(key, value) => updateAddress(key, value)}
          label={t("noSupportingDocumentForm.address.label")}
          addressForm={addressState}
          error={displayError}
        />
      </StyledContainer>
    </Page>
  );
};

const StyledContainer = styled.div`
  width: 100%;
`;
