import { useTranslation } from "react-i18next";
import { Page } from "../components/Page";
import { Spacer, colors, Button, TextCapitalized } from "@qivia/ui";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { ErrorPage } from "../components/ErrorPage";

export const NoSupportingDocument = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const transactionId = queryParameters.get("id");

  const [informationPage, setInformationPage] = useState<boolean>(false);
  if (transactionId === null) {
    return <ErrorPage />;
  }
  return (
    <>
      {!informationPage ? (
        <Page
          title={t("noSupportingDocument.title")}
          returnButton
          returnButtonTitle={t("noSupportingDocument.button.return") || ""}
          returnButtonAction={() => navigate(`/upload?id=${transactionId}`)}
        >
          <StyledContent>
            <Spacer y={1.5} />
            <Button
              title={t("noSupportingDocument.button.lostOrStolen")}
              onClick={() =>
                navigate(`/no_supporting_document_form?id=${transactionId}`)
              }
              height={3.5}
            />
            <Spacer y={1} />
            <Button
              title={t("noSupportingDocument.button.unavailable")}
              onClick={() => setInformationPage(true)}
              height={3.5}
            />
          </StyledContent>
        </Page>
      ) : (
        <Page
          title={t("noSupportingDocument.unavailable.title")}
          returnButton
          returnButtonTitle={t("noSupportingDocument.button.return") || ""}
          returnButtonAction={() => setInformationPage(false)}
          bottomButton={
            <Button
              title={t("noSupportingDocument.unavailable.button")}
              onClick={() =>
                navigate(`/no_supporting_document_form?id=${transactionId}`)
              }
              height={3.5}
            />
          }
        >
          <StyledContent>
            <TextCapitalized>
              {t("noSupportingDocument.unavailable.content")}
            </TextCapitalized>
            <Spacer y={1} />
            {["dateAndLocation", "amount", "tva", "type", "stamp"].map(
              (val) => {
                return (
                  <>
                    <Spacer y={0.5} />
                    <StyledOption>
                      <StyledRadioButton />
                      <Spacer x={1} />
                      <TextCapitalized>
                        {t(`noSupportingDocument.unavailable.option.${val}`)}
                      </TextCapitalized>
                    </StyledOption>
                    <Spacer y={0.5} />
                  </>
                );
              },
            )}
          </StyledContent>
        </Page>
      )}
    </>
  );
};

const StyledContent = styled.div`
  width: 100%;
  color: ${colors.black};
`;

const StyledOption = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRadioButton = styled.div`
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.75rem;
  border: 0.125rem solid ${colors.black};
`;
